import { Flex, Heading, Text } from "@app/domains/shared/design-system";
import styled, { DefaultTheme } from "styled-components";

const desktop = ({ theme }: { theme: DefaultTheme }) =>
  `min-width: ${theme.screens.m.max}`;

export const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;

  @media (${desktop}) {
    padding-top: 32px;
    padding-bottom: 16px;
  }
`;

export const Title = styled(Heading).attrs({ as: "h2" })`
  font-size: 20px;
  margin-top: 0;
`;

export const Description = styled(Text)`
  font-size: 16px;
  color: #717171;
  text-align: center;
  margin-bottom: 24px;
`;
