import {
  Flex,
  Icon as PomodoroIcon,
  Text as PomodoroText,
  TextLink as PomodoroTextLink,
} from "@app/domains/shared/design-system";
import { maxWidth } from "@app/utils/viewport";
import styled from "styled-components";

const bellowDesktop = ({ theme }: { theme: any }) =>
  `max-width: ${theme.screens.l.min}`;

export const WrapperContainer = styled.div`
  width: -webkit-fill-available;
  background: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => `0 ${theme.space.loose}`};

  @media (${bellowDesktop}) {
    display: none;
  }
`;

export const Wrapper = styled(Flex)`
  --padding: clamp(
    ${({ theme }) => `${theme.space.regular}, 1.1dvw, ${theme.space.large}`}
  );
  padding: var(--padding) 0;
  gap: ${({ theme }) => theme.space.larger};
  max-width: ${maxWidth};
  box-sizing: border-box;
  align-items: center;
  margin: auto;
`;

export const Logo = styled.div`
  width: 50px;
  height: 50px;
  padding: 2px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.grayLight};
  border: solid 1px ${({ theme }) => theme.colors.grayLight};
  position: relative;
  cursor: pointer;

  img {
    border-radius: 50%;
  }
`;

export const Profile = styled(Flex)`
  position: relative;
  align-items: center;
  flex-shrink: 0;
`;

export const SuspendedMenuArea = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.white};
  position: absolute;
  right: 0%;
  top: 150%;
  border-radius: 0px 0px 16px 16px;
  width: 220px;
  height: auto;
  display: "flex";
`;

export const Container = styled(Flex)`
  align-items: center;
  cursor: pointer;
  gap: 8px;
`;

export const Icon = styled(PomodoroIcon)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const SwitchIcon = styled(PomodoroIcon)`
  & svg path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const Text = styled(PomodoroText)`
  font-size: ${({ theme }) => theme.fontSizes.tsm};
  ${({ theme, color }) => !color && `color: ${theme.colors.grayDarkest}`};
`;

export const UserName = styled(PomodoroText)`
  font-size: ${({ theme }) => theme.fontSizes.tsm};
  line-height: ${({ theme }) => theme.lineHeights.s};
  color: ${({ theme }) => theme.colors.black};
  font-weight: 500;
`;

export const UserAddress = styled(PomodoroText)`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.grayDarkest};
  line-height: ${({ theme }) => theme.lineHeights.s};
`;

export const SwitchMerchant = styled(PomodoroText)`
  font-size: ${({ theme }) => theme.fontSizes.s};
  color: ${({ theme }) => theme.colors.grayDarkest};
`;

export const MerchantName = styled(PomodoroText)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.black};
`;

export const MinimumOrderValue = styled(PomodoroText)`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: ${({ theme }) => theme.fontSizes.s};
  color: ${({ theme }) => theme.colors.grayDarkest};
`;

export const Divider = styled.div`
  width: 1px;
  height: 25.08px;

  background: ${({ theme }) => theme.colors.gray};
`;

export const HeaderItem = styled(Flex)`
  align-items: center;
  flex-direction: row;
  flex-shrink: 0;
  cursor: pointer;
  gap: 5px;
`;

export const BagItem = styled(Flex)`
  background: ${({ theme }) => theme.colors.primary};
  align-items: center;
  flex-direction: row;
  flex-shrink: 0;
  cursor: pointer;
  gap: 8px;
  padding: 6px 16px 6px 16px;
  border-radius: 80px;

  svg {
    color: white;
  }
`;

export const BagWrapper = styled(Flex)`
  flex-direction: column;
  span {
    line-height: 14px;
    color: white;
  }
`;

export const BagPrice = styled(PomodoroText)`
  font-size: ${({ theme }) => theme.fontSizes.tsm};
  color: ${({ theme }) => theme.colors.grayDarkest};
`;

export const BagCounter = styled(PomodoroText)`
  color: ${({ theme }) => theme.colors.grayDarkest};
  font-size: 10px;
`;
