import { useIsMobile } from "@app/domains/shared/hooks";
import NextImage from "next/image";

import { Desktop } from "./Desktop";
import { Mobile } from "./Mobile";
import * as S from "./styles";
import { Order } from "../../models";
import { Button } from "@ifood/pomodoro-components";

type OrderCanceledProps = {
  order: Order;
  onClose: () => void;
};

export const OrderCanceled: React.VFC<OrderCanceledProps> = ({
  order,
  onClose,
}) => {
  const isMobile = useIsMobile();

  const DialogToRender = isMobile ? Mobile : Desktop;

  return (
    <DialogToRender open={true} onClose={onClose}>
      <S.Container>
        <S.Title>O pedido #{order.shortId} foi cancelado</S.Title>
        <NextImage
          height={174}
          width={174}
          src={"/images/order/order-canceled.svg"}
          alt=""
        />
        <S.Description>
          Caso o seu pagamento tenha sido online, não se preocupe, o reembolso
          será feito automaticamente.
        </S.Description>
        <Button onClick={onClose} size="small">
          Ok, entendi
        </Button>
      </S.Container>
    </DialogToRender>
  );
};
