import { Flex, Text } from "@app/domains/shared/design-system";
import styled from "styled-components";

export const Container = styled(Flex)`
  position: relative;
  max-width: 500px;
  margin: auto;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding-right: ${({ theme }) => theme.space.larger};
  padding-bottom: ${({ theme }) => theme.space.loosest};
  padding-left: ${({ theme }) => theme.space.larger};
`;

export const ProgressBarContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.space.larger};
`;

export const CountdownText = styled(Text)`
  margin-left: ${({ theme }) => theme.space.large};

  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;
