/* eslint-disable max-lines */
import { Item } from "@app/domains/catalog/models";
import { useMerchant } from "@app/domains/merchant/context";
import { Flex, Icon, Text } from "@app/domains/shared/design-system";
import { ecommerceEvents } from "@app/domains/shared/ecommerce-events";

import { Sizes, withoutLoader } from "@app/domains/shared/image-utils";
import NextImage from "next/image";

import { ChangeEventHandler, useEffect, useState } from "react";
import { useCheckout } from "@app/domains/checkout/context";
import * as S from "./styles";

import { catalogAboyeur } from "@app/domains/catalog/events";
import { Observation } from "../Observation";
import { QuickAddItem } from "../QuickAddItem";

type ItemDetailsProps = {
  item: Item;
};

export const ItemDetails: React.VFC<ItemDetailsProps> = ({ item }) => {
  const { order, getItemInOrder } = useCheckout();
  const { merchant } = useMerchant();

  useEffect(() => {
    if (!item) return;
    catalogAboyeur.events.item.openItemPage(item.description, item.id);
    ecommerceEvents.viewDetail(item);
  }, [item]);

  const [observation, setObservation] = useState(
    () => getItemInOrder(item.id)?.observation ?? "",
  );

  useEffect(() => {
    const itemInOrder = getItemInOrder(item.id);
    if (!itemInOrder) return;
    setObservation(itemInOrder.observation ?? "");
  }, [order.itemsList, item]);

  if (!item) return null;

  const handleObservationChange: ChangeEventHandler<HTMLTextAreaElement> = (
    e,
  ) => {
    setObservation(e.target.value);
  };

  const price = item.getBasePrice().format();
  const isPromotionalItem = item.isPromotionalItem();
  const originalPrice = (item.unitOriginalPrice ?? item.unitPrice).format();
  const discountPercentage = item.getDiscountPercentage();
  const enableObservationField = Boolean(
    merchant.merchantConfigs.hasItemObservation && item.isWeigthVariable(),
  );

  const additionalInfo = merchant.isPharmacy()
    ? item.additionalInfo
    : undefined;

  return (
    <>
      <S.Container>
        <S.Image>
          <NextImage
            loader={withoutLoader}
            src={item.getLogoUrl(Sizes.HIGH)}
            alt={item.details}
            layout="fill"
            objectFit="cover"
          />
        </S.Image>

        <S.ItemData>
          <S.Description>
            <Flex flexDirection="column">
              <S.Title>{item.description}</S.Title>

              {item.details && !additionalInfo && (
                <Text fontWeight="400" mb="0" color="grayDarkest">
                  {item.details}
                </Text>
              )}
            </Flex>

            <S.PriceContainer>
              {isPromotionalItem && (
                <Flex alignItems="center" gap="8px">
                  <S.StrikethroughedText
                    variant="medium"
                    color="grayDarker"
                    fontWeight={300}
                  >
                    {originalPrice}
                  </S.StrikethroughedText>
                  <S.Discount>-{discountPercentage}%</S.Discount>
                </Flex>
              )}

              <S.TextPrice color={isPromotionalItem ? "positive" : "black"}>
                {price}
                <b>
                  {item.isWeigthVariable() &&
                    `A granel (${item.getFormattedUnit()} cada)`}
                </b>
              </S.TextPrice>
            </S.PriceContainer>

            {item.isWeigthVariable() && (
              <Flex flexDirection="column">
                <Flex justifyContent="space-between">
                  <Text fontSize="14px" color="grayDarkest">
                    Preço do quilo
                  </Text>
                  <Text fontSize="14px" color="grayDarkest">
                    {item.getWeightPrice().format()}
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <Text fontSize="14px" color="grayDarkest">
                    Peso estimado por item
                  </Text>
                  <Text fontSize="14px" color="grayDarkest">
                    {item.getProductWeight()}
                  </Text>
                </Flex>
              </Flex>
            )}

            {item.hasDietaryRestriction() && (
              <>
                {item.getDietaryRestrictionInformation().map((tag) =>
                  tag ? (
                    <S.Tags key={tag.label}>
                      <S.Circle>
                        <Icon size="xs" component={tag.icon} mr="smallest" />
                      </S.Circle>
                      {tag.label}
                    </S.Tags>
                  ) : null,
                )}
              </>
            )}
          </S.Description>

          <QuickAddItem
            catalogItem={item}
            observation={observation}
            enableExtraButtons={true}
          ></QuickAddItem>

          {enableObservationField && (
            <Observation
              value={observation}
              onChange={handleObservationChange}
            />
          )}
        </S.ItemData>
      </S.Container>

      {additionalInfo && (
        <S.FarmaContainer>
          <S.FarmaDescription>
            {item.details && (
              <>
                <S.Title>Descrição do produto</S.Title>
                <S.FarmaDetails>{item.details}</S.FarmaDetails>
              </>
            )}
          </S.FarmaDescription>
          <S.FarmaTable>
            <S.Title>Características do produto</S.Title>
            <S.TableDivider />
            {additionalInfo.split("\n").map((line, index, array) => {
              const [firstPart, ...rest] = line.split(":");
              return (
                <>
                  <Text
                    fontWeight="400"
                    mt={index === 0 ? "12px" : "0"}
                    color="black"
                    mb="0"
                  >
                    {rest.length > 0 ? (
                      <>
                        <b>{firstPart}:</b> {rest.join(":")}
                      </>
                    ) : (
                      line
                    )}
                  </Text>
                  {index !== array.length - 1 && <S.TableDivider />}
                </>
              );
            })}
          </S.FarmaTable>
        </S.FarmaContainer>
      )}
    </>
  );
};
