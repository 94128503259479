import {
  Divider as PomodoroDivider,
  Button as PomodoroButton,
} from "@app/domains/shared/design-system";
import styled, { DefaultTheme } from "styled-components";

const belowDesktop = ({ theme }: { theme: DefaultTheme }) =>
  `max-width: ${theme.screens.m.max}`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Divider = styled(PomodoroDivider)`
  margin: ${({ theme }) => theme.space.none};
  border-color: ${({ theme }) => theme.colors.grayLight};
`;

export const Button = styled(PomodoroButton)`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.s};
  margin: ${({ theme }) => theme.space.small} 0px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grayLight} !important;
  }

  @media (${belowDesktop}) {
    padding: ${({ theme }) => `${theme.space.smallest} ${theme.space.regular}`};
  }
`;
