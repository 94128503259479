import { Money } from "@app/domains/shared/models";
import { DeliveryMethod, TimeSlot } from "@app/domains/merchant/models";
import {
  Carousel,
  DrawerWithPush,
  Text,
} from "@app/domains/shared/design-system";

import * as S from "./styles";
import { Flex } from "@ifood/pomodoro-components";
import { useEffect, useState } from "react";
import { useCheckout } from "@app/domains/checkout/context";
import { dateToDayName } from "@app/domains/shared/date-utils";
import { useUpdateDeliveryMethod } from "@app/domains/checkout/hooks";
import { useIsMobile } from "@app/domains/shared/hooks";
import { checkoutAboyeur } from "@app/domains/checkout/events";
import { parseISO } from "date-fns";

type DeliverySlotsProps = {
  open: boolean;
  onClose: () => void;
};

export type SlotOption = {
  date: string;
  price: string;
  dateName: string;
  dateNumber: number;
  timeSlots: TimeSlot[];
};

export const DeliverySlots: React.VFC<DeliverySlotsProps> = ({
  open,
  onClose,
}) => {
  const isMobile = useIsMobile();
  const ModalComponent = isMobile ? DrawerWithPush : S.Dialog;

  const { order } = useCheckout();
  const { updateDeliveryMethod } = useUpdateDeliveryMethod();

  const deliveryMethod: DeliveryMethod = order.deliveryMethod;
  const currentTimeSlot = deliveryMethod?.schedule?.selectedTimeSlot;
  const timeSlots = deliveryMethod?.schedule?.timeSlots;
  const slots = groupTimeSlotsByDate(timeSlots);

  const currentDate =
    slots?.find((slot) => {
      return slot.date == currentTimeSlot?.date;
    })?.date ?? slots?.[0]?.date;

  const [date, setDate] = useState(currentDate);
  const filteredTimeSlots =
    slots?.find((slot) => slot.date == date)?.timeSlots ?? [];

  const handleDateClick = (date: string) => setDate(date);
  const handleSlotClick = (selectedTimeSlot: TimeSlot) => {
    deliveryMethod.schedule.selectedTimeSlot = selectedTimeSlot;
    updateDeliveryMethod(deliveryMethod);
    checkoutAboyeur.events.deliveryMethod.clickSelectedDelivery(
      selectedTimeSlot.date,
      `${selectedTimeSlot.startTime} - ${selectedTimeSlot.endTime}`,
    );
    onClose();
  };

  useEffect(() => {
    if (!open) return;
    checkoutAboyeur.events.deliveryMethod.viewDeliveryMethods();
  }, [open]);

  if (!slots || slots.length === 0) return null;
  if (!date) setDate(currentDate);

  return (
    <ModalComponent open={open} onClose={onClose}>
      <S.Wrapper>
        <Flex
          aria-label="Titulo e descrição do modal"
          flexDirection="column"
          alignItems="center"
          gap="smaller"
        >
          <S.Title>
            {deliveryMethod.isDeliveryMode() ? "Entrega" : "Retirada"} agendada
          </S.Title>

          <S.Subtitle>
            Escolha o dia e o horário para
            {deliveryMethod.isDeliveryMode() ? " receber " : " retirar "}
            seu pedido
          </S.Subtitle>
        </Flex>

        <Carousel simultaneousItems={4} gap="38px" mobileWidth="80px">
          {slots.map((slot) => {
            return (
              <S.DateCard
                key={slot.date}
                active={slot.date == date}
                onClick={() => handleDateClick(slot.date)}
              >
                <Text fontWeight="500">{slot.dateNumber}</Text>
                <Text color="grayDarkest">{slot.dateName}</Text>
                <Text
                  fontSize="small"
                  fontWeight={slot.price == "Grátis" ? "700" : "400"}
                  color={slot.price == "Grátis" ? "positive" : "black"}
                >
                  {slot.price}
                </Text>
              </S.DateCard>
            );
          })}
        </Carousel>

        <S.SlotsWrapper aria-label="Horários disponíveis para agendamento">
          {filteredTimeSlots.map((timeSlot) => {
            return (
              <S.SlotCard
                key={timeSlot.id}
                active={currentTimeSlot?.id == timeSlot.id}
                onClick={() => handleSlotClick(timeSlot)}
              >
                <Text
                  fontWeight={
                    currentTimeSlot?.id == timeSlot.id ? "500" : "400"
                  }
                >
                  {timeSlot.startTime} - {timeSlot.endTime}
                </Text>
                <Flex gap="small">
                  <Text
                    fontWeight={
                      currentTimeSlot?.id == timeSlot.id ? "500" : "400"
                    }
                    color={timeSlot.price == 0 ? "positive" : "black"}
                  >
                    {timeSlot.price === 0
                      ? "Grátis"
                      : new Money(timeSlot.price / 100).format()}
                  </Text>
                  <S.CircleCheck
                    active={currentTimeSlot?.id == timeSlot.id}
                  ></S.CircleCheck>
                </Flex>
              </S.SlotCard>
            );
          })}
        </S.SlotsWrapper>
      </S.Wrapper>
    </ModalComponent>
  );
};

function groupTimeSlotsByDate(timeSlots?: TimeSlot[]): SlotOption[] {
  if (!timeSlots) return undefined;
  return Object.values(
    timeSlots.reduce((acc, curr) => {
      const { date, price } = curr;
      if (!acc[date]) {
        acc[date] = {
          date: date,
          dateName: dateToDayName(date),
          dateNumber: parseISO(date).getDate(),
          price: price === 0 ? "Grátis" : new Money(price / 100).format(),
          timeSlots: [],
        };
      }
      acc[date].timeSlots.push(curr);
      return acc;
    }, {}),
  );
}
