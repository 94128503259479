import { useAddress } from "@app/domains/address/context";
import { checkoutAboyeur } from "@app/domains/checkout/events";
import { useMerchant } from "@app/domains/merchant/context";
import { DeliveryMethod } from "@app/domains/merchant/models";
import { Flex } from "@app/domains/shared/design-system";

import { useCheckout } from "@app/domains/checkout/context";
import * as S from "./styles";
import { Text } from "@ifood/pomodoro-components";
import { useEffect } from "react";

export const DeliverySelect: React.VFC = () => {
  const { order } = useCheckout();
  const { merchant } = useMerchant();
  const { openAddress, getAddress, setDeliveryMethod } = useAddress();

  function handleChangeAddress() {
    checkoutAboyeur.events.details.changeAddress();
    openAddress("MERCHANT");
  }

  const fixEmptyAddress = async () => {
    if (!order.address || !order.deliveryTime) {
      if (order.isDefaultDeliveryMethod()) {
        await handleTakeout();
        await handleDelivery();
      }
    }
  };

  useEffect(() => {
    fixEmptyAddress();
  }, []);

  async function handleTakeout() {
    try {
      if (order.isTakeoutDeliveryMethod()) return;
      const takeoutMethodResponse = await merchant.getTakeoutMethod();

      if (!takeoutMethodResponse) return;
      const takeoutMethod = DeliveryMethod.fromApi(takeoutMethodResponse);
      setDeliveryMethod(takeoutMethod);
    } catch (error: any) {
      checkoutAboyeur.events.catch.onError(error as Error);
    }
  }

  async function handleDelivery() {
    try {
      if (order.isDefaultDeliveryMethod()) return;
      const customerAddress = getAddress("MERCHANT");
      if (!customerAddress) return openAddress("MERCHANT");
      const deliveryMethodResponse = await merchant.getDeliveryMethod(
        customerAddress.coordinates.latitude,
        customerAddress.coordinates.longitude,
      );

      if (!deliveryMethodResponse) return openAddress("MERCHANT");
      const deliveryMethod = DeliveryMethod.fromApi(deliveryMethodResponse);
      setDeliveryMethod(deliveryMethod);
    } catch (error: any) {
      checkoutAboyeur.events.catch.onError(error as Error);
    }
  }

  return (
    <S.Wrapper>
      <Flex flexDirection="column" gap="regular" width="100%">
        <Flex>
          {merchant.hasDeliveryFeature() && (
            <S.ToogleDeliveryMode
              active={order.isDefaultDeliveryMethod()}
              pr={order.isTakeoutDeliveryMethod() ? "32px" : "0"}
              onClick={handleDelivery}
            >
              Entrega
            </S.ToogleDeliveryMode>
          )}
          {merchant.hasTakeoutFeature() && (
            <S.ToogleDeliveryMode
              active={order.isTakeoutDeliveryMethod()}
              pl={order.isDefaultDeliveryMethod() ? "32px" : "0"}
              onClick={handleTakeout}
            >
              Retirada
            </S.ToogleDeliveryMode>
          )}
        </Flex>
        <S.Container>
          <Flex>
            {order.isDefaultDeliveryMethod() && order.address && (
              <Flex flexDirection="column">
                <S.DeliveryAddress>Entregar no endereço</S.DeliveryAddress>
                <Text fontSize="14px" lineHeight="16px" color="grayDarkest">
                  {`${order.address.streetName}, ${order.address.streetNumber} - ${order.address.neighborhood} - ${order.address.city}/ ${order.address.state}`}
                </Text>
                <Text fontSize="14px" lineHeight="16px" color="grayDarkest">
                  {`CEP: ${order.address.postalCode}`}
                </Text>
              </Flex>
            )}
            {order.isTakeoutDeliveryMethod() && (
              <Flex centered>
                <Flex flexDirection="column">
                  <S.DeliveryAddress>Retirar no endereço</S.DeliveryAddress>
                  <Text fontSize="14px" lineHeight="16px" color="grayDarkest">
                    {`${merchant.address.streetName}, ${merchant.address.streetNumber} - ${merchant.address.district} - ${merchant.address.city}/ ${merchant.address.state}`}
                  </Text>
                  <Text fontSize="14px" lineHeight="16px" color="grayDarkest">
                    {`CEP: ${merchant.address.zipCode}`}
                  </Text>
                </Flex>
              </Flex>
            )}
          </Flex>
          {order.isDefaultDeliveryMethod() && (
            <S.SwitchAddress onClick={handleChangeAddress}>
              Trocar endereço
            </S.SwitchAddress>
          )}
        </S.Container>
      </Flex>
    </S.Wrapper>
  );
};
