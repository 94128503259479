import { useMerchant } from "@app/domains/merchant/context";
import { dateDiffInDays } from "@app/domains/shared/date-utils";
import { Heading, Text } from "@app/domains/shared/design-system";
import React, { useMemo, useState } from "react";

import { orderAboyeur } from "../../events";
import { useHasRatedOrder } from "../../hooks";
import { Order } from "../../models";
import { RatingDrawer } from "../RatingDrawer";
import { Star } from "../Star";
import * as S from "./styles";

type Props = {
  order?: Order;
};

export const Rating: React.VFC<Props> = ({ order }) => {
  const [rating, setRating] = useState<number>(0);
  const { merchant } = useMerchant();

  const orderId = useMemo(() => order?.id ?? "", [order]);

  const [hasRated] = useHasRatedOrder(orderId);

  const title = useMemo(() => {
    if (!merchant.merchantConfigs.isOrderRatingForMerchantEnabled)
      return "Avalie sua experiência com o nosso site";
    return "O que você achou do seu pedido?";
  }, [merchant.merchantConfigs.isOrderRatingForMerchantEnabled]);

  function handleOpen(value: number) {
    setRating(value);
    orderAboyeur.events.orderRating.openModal(merchant.id, rating);
  }

  function handleClose() {
    setRating(0);
    orderAboyeur.events.orderRating.closeModal(merchant.id);
  }

  if (
    hasRated ||
    (orderId && !merchant.merchantConfigs.isOrderRatingForMerchantEnabled) ||
    (order && dateDiffInDays(order.createdAt, new Date()) > 4)
  )
    return null;

  const getStarKey = (index: number) => `star-${index}`;

  return (
    <S.Wrapper>
      <Heading as="h3" variant="smallest" mt={0} mb="small">
        {title}
      </Heading>
      <S.StarWrapper>
        {Array.from({ length: 5 }).map((_, index) => (
          <Star
            key={getStarKey(index)}
            onClick={() => handleOpen(index + 1)}
            filled={index < rating}
          />
        ))}
      </S.StarWrapper>
      <Text variant="small" color="grayDarker" mt="small">
        Escolha de 1 a 5 estrelas para classificar
      </Text>
      {rating > 0 && (
        <RatingDrawer
          applicationName={merchant.name}
          rating={rating}
          onClose={handleClose}
          isMerchantRating={
            merchant.merchantConfigs.isOrderRatingForMerchantEnabled
          }
          orderId={orderId}
        />
      )}
    </S.Wrapper>
  );
};
