import { Button, Flex } from "@app/domains/shared/design-system";
import styled from "styled-components";

export const Container = styled(Flex)`
  position: relative;
  max-width: 500px;
  margin: auto;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding-right: ${({ theme }) => theme.space.larger};
  padding-bottom: ${({ theme }) => theme.space.loosest};
  padding-left: ${({ theme }) => theme.space.larger};
`;

export const FollowOrderButton = styled(Button)`
  width: 100%;

  font-size: ${({ theme }) => theme.fontSizes.s};
`;
