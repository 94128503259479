import { checkoutAboyeur } from "@app/domains/checkout/events";
import { PaymentMethod } from "@app/domains/checkout/models";
import {
  Flex,
  Text,
  Input as PomodoroInput,
} from "@app/domains/shared/design-system";
import { Money } from "@app/domains/shared/models";
import { ChangeEvent, useMemo, useState } from "react";

import { useCheckout } from "@app/domains/checkout/context";
import * as S from "./styles";
import { Heading } from "@ifood/pomodoro-components";

type ChooseMoneyChangeProps = {
  onBack: () => void;
  onClose: () => void;
  selectedPaymentMethods: PaymentMethod[];
};

export const ChooseMoneyChange: React.VFC<ChooseMoneyChangeProps> = ({
  onBack,
  onClose,
  selectedPaymentMethods,
}) => {
  const {
    setPaymentMethod,
    order: { totalOrder },
  } = useCheckout();
  const [maskedMoney, setMaskedMoney] = useState<string>(() => {
    const defaultMoney = new Money(0);
    return defaultMoney.toString();
  });

  function handleWithoutCashChange() {
    const cashMethod = selectedPaymentMethods[0];
    checkoutAboyeur.events.payment.haveMoneyChange(false);

    setPaymentMethod(cashMethod.withCashChange());
    onClose();
  }

  const handleWithCashChange = (value: string) => {
    const cashMethod = selectedPaymentMethods[0];
    const cashChange = new Money(value);

    cashMethod.withCashChange(cashChange);

    setPaymentMethod(cashMethod);
    onClose();
  };

  const handleCashChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    const newValue = new Money(Number(value.replace(/\D/g, "")) / 100);
    setMaskedMoney(newValue.toString());
  };

  const enabledConfirmButton = useMemo(
    () => new Money(maskedMoney).getValue() > totalOrder.getValue(),
    [maskedMoney, totalOrder],
  );

  return (
    <Flex flexDirection="column" height="100%">
      <Heading fontSize="20px" lineHeight="24px" mt="0" mb="small">
        Troco pra quanto?
      </Heading>
      <Flex flexDirection="column" height="100%">
        <Text>
          Digite quanto vai pagar em dinheiro para que o entregador leve seu
          troco.
        </Text>
        <S.ContainerCurrencyInput>
          <PomodoroInput
            type="tel"
            name="money"
            value={maskedMoney}
            onChange={handleCashChange}
          />
        </S.ContainerCurrencyInput>
        <S.ContainerMoneyButtons>
          <S.MoneyButton variant="secondary" onClick={handleWithoutCashChange}>
            Não quero troco
          </S.MoneyButton>
          <S.MoneyButton
            variant="primary"
            disabled={!enabledConfirmButton}
            onClick={() => handleWithCashChange(maskedMoney)}
          >
            Confirmar
          </S.MoneyButton>
        </S.ContainerMoneyButtons>
      </Flex>
    </Flex>
  );
};
