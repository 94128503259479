import { Flex } from "@app/domains/shared/design-system";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  min-width: 320px;
  height: 100%;
  flex: auto;
  margin-top: auto;
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;
