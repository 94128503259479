import {
  Flex,
  Heading,
  Icon as PomodoroIcon,
  Text as PomodoroText,
} from "@app/domains/shared/design-system";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
  flex-direction: column;
  padding: 16px 0 8px 0px;
  width: 100%;
  gap: ${({ theme }) => theme.space.none};
  border: solid 1px ${({ theme }) => theme.colors.gray};
  background: white;
  z-index: 1;
  border-radius: 8px;
`;

export const Title = styled(Heading)`
  margin-top: 0;
  padding: 0px ${({ theme }) => theme.space.regular};
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: ${({ theme }) => theme.lineHeights.m};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Option = styled(Flex)`
  gap: ${({ theme }) => theme.space.smaller};
  padding: ${({ theme }) => theme.space.small} 0px;
  margin: 0 ${({ theme }) => theme.space.regular};
  border-top: solid 1px ${({ theme }) => theme.colors.grayLight};
  background-color: white;
  cursor: pointer;

  &:hover span {
    color: ${({ theme }) => theme.colors.primary} !important;
  }
`;

export const Icon = styled(PomodoroIcon)`
  color: ${({ theme }) => theme.colors.black};
`;

export const TextLink = styled(PomodoroText)`
  font-size: ${({ theme }) => theme.fontSizes.tsm};
  color: ${({ theme }) => theme.colors.black};
`;
