import { useCatalog } from "@app/domains/catalog/context";
import React, { useMemo } from "react";

import { useRouter } from "next/router";
import { ItemGridLayout } from "@app/domains/catalog/layout";

export const AislePage: React.VFC = () => {
  const { catalog } = useCatalog();
  const router = useRouter();
  const slug = router.query.slug as string;

  const aisleResult = useMemo(
    () => catalog.categories.find((category) => category.code == slug),
    [catalog],
  );

  const aisleItems = useMemo(() => {
    return aisleResult?.items.map((item, index) => {
      item.order = index;
      return item;
    });
  }, [aisleResult]);

  const aisleLink = catalog.links.find((link) => link.uuid == slug);
  const aisleName = aisleLink ? aisleLink.name : aisleResult.name;

  return (
    <ItemGridLayout
      title={aisleName}
      catalogItems={aisleItems}
    ></ItemGridLayout>
  );
};
