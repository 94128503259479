import { Item } from "@app/domains/checkout/models";
import { useMerchant } from "@app/domains/merchant/context";
import { Text } from "@app/domains/shared/design-system";
import NextImage from "next/image";

import * as S from "./styles";
import { Flex } from "@ifood/pomodoro-components";
import { Close } from "@ifood/pomodoro-icons";
import { QuickAddItem } from "@app/domains/catalog/views";
import { useCheckout } from "@app/domains/checkout/context";
import { Sizes, withoutLoader } from "@app/domains/shared/image-utils";
import { memo, useCallback, useMemo } from "react";
import { useRouter } from "next/router";
import { checkoutAboyeur } from "@app/domains/checkout/events";

export const ListItem: React.VFC<{
  item: Item;
}> = memo(({ item }) => {
  const router = useRouter();
  const { merchant } = useMerchant();
  const { catalogItem } = item;
  const { removeItem, setCheckoutDetailsStatus } = useCheckout();

  const handleRemoveItemClick = useCallback(() => {
    removeItem(catalogItem);
  }, [removeItem, catalogItem]);

  const imageSrc = useMemo(
    () => catalogItem.getLogoUrl(Sizes.LOW),
    [catalogItem],
  );

  const isPromotionalItem = useMemo(
    () => catalogItem.isPromotionalItem(),
    [catalogItem],
  );

  const openItemPage = useCallback(async () => {
    setCheckoutDetailsStatus("IDLE");
    return router.push(`/${merchant.query}/item/${catalogItem.code}`);
  }, [router, merchant.query, catalogItem.code]);

  const handleFindSimilar = useCallback(() => {
    setCheckoutDetailsStatus("IDLE");
    checkoutAboyeur.events.details.findSimilarItens(item);
    const term = item.catalogItem.description.split(" ").at(0);
    router.push(`/${merchant.query}/busca/${term}`);
  }, [router, merchant.query, item]);

  const unavailable = item.status == "UNAVAILABLE";
  const imageSaturation = unavailable ? 0 : 1;

  return (
    <S.ListItem>
      <S.Container>
        <S.ImageWrapper saturate={imageSaturation}>
          <NextImage
            loader={withoutLoader}
            src={imageSrc}
            alt={catalogItem.details}
            layout="fill"
            objectFit="cover"
            onClick={openItemPage}
          />
          {unavailable && (
            <S.RemoveIcon
              onClick={handleRemoveItemClick}
              component={Close}
            ></S.RemoveIcon>
          )}
        </S.ImageWrapper>
        <S.Description>
          <Flex justifyContent="center" flexDirection="column" flex="auto">
            {unavailable && <S.UnavailableTag>Indisponível</S.UnavailableTag>}
            <S.ItemName>
              {item.quantity}x {catalogItem.description}
            </S.ItemName>
            {item.observation.length ? (
              <S.Observation>Obs: {item.observation}</S.Observation>
            ) : null}
          </Flex>
          {unavailable ? (
            <S.FindSimilar onClick={handleFindSimilar}>
              Escolher similar
            </S.FindSimilar>
          ) : (
            <S.PriceContainer>
              <Flex gap="small" justifyContent="right">
                {isPromotionalItem && (
                  <S.StrikethroughedText variant="large" color="grayDarker">
                    {item.calculateOriginalPrice().format()}
                  </S.StrikethroughedText>
                )}
                <S.PriceText color="positive" variant="large">
                  {item.calculatePrice().format()}
                </S.PriceText>
              </Flex>
              <Text
                fontWeight="400"
                color="grayDarkest"
                variant="small"
                textAlign="right"
              >
                {item.unit == "WEIGHT"
                  ? `${catalogItem.getWeightPrice().format()} / kg`
                  : `${catalogItem.getBasePrice().format()} / unidade`}
              </Text>
            </S.PriceContainer>
          )}
        </S.Description>
      </S.Container>
      <Flex alignItems="center" justifyContent="space-between">
        <S.RemoveItem
          onClick={handleRemoveItemClick}
          color={unavailable ? "#eb0033" : "grayDarker"}
          fontWeight="bold"
        >
          Remover
        </S.RemoveItem>
        {!unavailable && (
          <S.EditQuantity>
            <QuickAddItem catalogItem={catalogItem} />
          </S.EditQuantity>
        )}
      </Flex>
    </S.ListItem>
  );
});
