import { useMerchant } from "@app/domains/merchant/context";
import { Z_INDEXES } from "@app/domains/shared/constants";
import { Text } from "@app/domains/shared/design-system";
import { useIsMobile } from "@app/domains/shared/hooks";

import { Desktop } from "./Desktop";
import { Mobile } from "./Mobile";

type TakeoutAddressProps = {
  open: boolean;
  onClose: () => void;
};

export const TakeoutAddress: React.VFC<TakeoutAddressProps> = ({
  open,
  onClose,
}) => {
  const { merchant } = useMerchant();
  const isMobile = useIsMobile();

  const WrapperToRender = isMobile ? Mobile : Desktop;

  if (!open) return null;

  return (
    <WrapperToRender
      isOpen={true}
      onClose={onClose}
      zIndex={Z_INDEXES.TAKEOUT_ADDRESS_DRAWER}
      title="Endereço para retirada"
      data-testid="takeout-address"
    >
      <Text variant="medium" textAlign="center">
        {merchant.getStreetAddress()}
      </Text>
      <Text variant="medium" textAlign="center">
        {merchant.getNeighborhoodAddress()}
      </Text>
    </WrapperToRender>
  );
};
