import {
  Button as PomodoroButton,
  ActionSheet,
} from "@app/domains/shared/design-system";
import styled from "styled-components";

export const Footer = styled(ActionSheet.Footer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.space.none};
  margin-top: ${({ theme }) => theme.space.regular};

  @media (min-width: 490px) {
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
  }
`;

export const Button = styled(PomodoroButton)`
  width: 100%;

  font-size: ${({ theme }) => theme.fontSizes.s};
  margin-top: ${({ theme }) => theme.space.small};

  @media (min-width: 490px) {
    &:first-child {
      margin-top: ${({ theme }) => theme.space.none};
    }

    & {
      margin-top: ${({ theme }) => theme.space.none};
    }
  }
`;
