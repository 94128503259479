import { DrawerWithPush } from "@app/domains/shared/design-system";
import NextImage from "next/image";

import * as S from "./styles";

type PixPaymentHelpProps = {
  onClose: () => void;
};

export const PixPaymentHelp: React.VFC<PixPaymentHelpProps> = ({ onClose }) => {
  return (
    <DrawerWithPush open={true} onClose={onClose}>
      <S.Wrapper>
        <NextImage
          width={180}
          height={150}
          src={"/images/order/pix-help.svg"}
        />

        <S.Heading as="h3">Como pagar pedidos com Pix</S.Heading>

        <S.Step>
          <S.StepHeading>1º passo</S.StepHeading>

          <S.StepDescription>Copie o código que foi gerado.</S.StepDescription>
        </S.Step>
        <S.Step>
          <S.StepHeading>2º passo</S.StepHeading>

          <S.StepDescription>
            Abra um aplicativo em que você tenha o Pix habilitado e use a opção
            Pix Copia e Cola.
          </S.StepDescription>
        </S.Step>
        <S.Step>
          <S.StepHeading>3º passo</S.StepHeading>

          <S.StepDescription>
            Cole o código, confirme o valor e faça o pagamento. Ele será
            confirmado na hora.
          </S.StepDescription>
        </S.Step>

        <S.Button onClick={onClose} aria-label="Ok, entendi">
          Ok, entendi
        </S.Button>
      </S.Wrapper>
    </DrawerWithPush>
  );
};
