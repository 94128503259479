import { useCatalog } from "@app/domains/catalog/context";
import React, { useMemo } from "react";

import { useRouter } from "next/router";
import { ItemGridLayout } from "@app/domains/catalog/layout";

export const SearchResults: React.VFC = () => {
  const { catalog } = useCatalog();
  const router = useRouter();
  const term = router.query.term as string;

  const searchResult = useMemo(
    () =>
      catalog
        .getVisibleCategories()
        .find((category) => category.name == "Resultados da busca"),
    [catalog],
  );

  const searchItems = useMemo(() => {
    return searchResult?.items.map((item, index) => {
      item.order = index;
      return item;
    });
  }, [searchResult]);

  return (
    <ItemGridLayout
      title={term.replaceAll("+", " ")}
      catalogItems={searchItems}
    ></ItemGridLayout>
  );
};
