import {
  DrawerWithPush,
  Dialog as DefaultDialog,
} from "@app/domains/shared/design-system";
import { Flex } from "@ifood/pomodoro-components";
import styled, { DefaultTheme } from "styled-components";

const belowDesktop = ({ theme }: { theme: DefaultTheme }) =>
  `max-width: ${theme.screens.m.max}`;

export const Wrapper = styled.div`
  position: relative;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

export const Content = styled.div`
  margin-top: ${({ theme }) => theme.space.largest};

  @media (${belowDesktop}) {
    padding: 0 ${({ theme }) => theme.space.regular};
    flex-direction: column;
    display: flex;
    margin-top: 0;
    height: 100%;

    h1 {
      font-size: ${({ theme }) => theme.fontSizes.l};
      font-weight: 500;
    }
  }
`;

export const Buttons = styled(Flex)`
  margin-top: ${({ theme }) => theme.space.largest};
  gap: ${({ theme }) => theme.space.large};

  button {
    font-size: ${({ theme }) => theme.fontSizes.s};
    font-weight: 700;
  }

  @media (${belowDesktop}) {
    margin-top: auto;
    flex-direction: column-reverse;
    gap: ${({ theme }) => theme.space.regular};
    margin-bottom: ${({ theme }) => theme.space.larger};
  }
`;

export const BreadcrumbDesktop = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${({ theme }) => theme.space.smaller};

  & a {
    color: ${({ theme }) => theme.colors.grayDarkest};
    font-family: ${({ theme }) => theme.font.family.ifood.body};
    font-size: ${({ theme }) => theme.fontSizes.tsm};
    line-height: ${({ theme }) => theme.fontSizes.tsm};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  & svg {
    stroke: ${({ theme }) => theme.colors.gray};
  }

  & :last-child {
    color: ${({ theme }) => theme.colors.black};
    font-weight: 500;
  }

  @media (${belowDesktop}) {
    display: none;
  }
`;

export const BreadcrumbMobile = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  width: -webkit-fill-available;
  position: sticky;
  top: 0;
  padding: 12px 16px;
  background: white;
  z-index: 1;
  box-shadow: 0 0 6px 2px #00000017;
  margin-top: -${({ theme }) => theme.space.regular};

  svg {
    position: absolute;
    top: 12px;
    left: 16px;
    path {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  span {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
  }

  @media (${belowDesktop}) {
    display: flex;
  }
`;

export const Dialog = styled(DefaultDialog)`
  max-width: 500px;

  h1 {
    margin: 0;
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSizes.l};
    line-height: ${({ theme }) => theme.fontSizes.xl};
  }

  span {
    font-size: ${({ theme }) => theme.fontSizes.tsm};
    line-height: ${({ theme }) => theme.fontSizes.m};
    color: ${({ theme }) => theme.colors.grayDarkest};
  }

  button {
    font-size: ${({ theme }) => theme.fontSizes.s};
    font-weight: 700;
  }

  & :last-child {
    gap: 16px;
  }
`;

export const Drawer = styled(DrawerWithPush)`
  h1 {
    margin: 0 auto;
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSizes.m};
    line-height: ${({ theme }) => theme.fontSizes.l};
  }

  span {
    font-size: ${({ theme }) => theme.fontSizes.tsm};
    line-height: ${({ theme }) => theme.fontSizes.m};
    color: ${({ theme }) => theme.colors.grayDarkest};
  }

  & > :last-child {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.space.large};

    div {
      padding: 0;
      text-align: center;
    }

    button {
      font-size: ${({ theme }) => theme.fontSizes.s};
      font-weight: 700;
      width: 100%;
    }
  }
`;
