import { useCheckout } from "@app/domains/checkout/context";
import { getBrandImageURL } from "@app/domains/shared/image-utils";
import { Flex, Icon } from "@ifood/pomodoro-components";
import NextImage from "next/image";
import * as S from "./styles";
import Pix from "../PaymentSelector/Status/SelectPaymentType/Pix";
import { useMemo } from "react";
import { CardFilled } from "@ifood/pomodoro-icons";

export const SelectedPayment: React.FC<{ onSwitch: () => void }> = ({
  onSwitch,
}) => {
  const {
    order,
    order: { paymentMethod, cardToken },
  } = useCheckout();

  const isOfflineSelected = paymentMethod?.isOffline();
  const isPixSelected = paymentMethod?.isPix();
  const isOnlineSelected = paymentMethod?.isOnline() && !isPixSelected;

  const brandImageURL = useMemo(() => {
    return getBrandImageURL(paymentMethod);
  }, [paymentMethod]);

  const paymentName = useMemo(() => {
    if (!paymentMethod) return "Formas de pagamento";
    return paymentMethod.getLabel();
  }, [paymentMethod]);

  const paymentDescription = useMemo(() => {
    if (!paymentMethod) return "Escolha uma opção";
    if (isPixSelected) return "Pagamento online com pix";
    if (isOnlineSelected && cardToken && paymentMethod.brand) {
      return `${paymentMethod.brand.description} •••• ${cardToken.last_four_digits}`;
    }
    return order.isTakeoutDeliveryMethod()
      ? "Pagar na retirada"
      : "Pagar na entrega";
  }, [cardToken, paymentMethod, isOnlineSelected, isPixSelected]);

  return (
    <Flex>
      {!paymentMethod && (
        <PaymentButton
          ariaLabel="Escolher pagamento"
          IconComponent={<Icon component={CardFilled} height="18" width="22" />}
          paymentName={paymentName}
          paymentDescription={paymentDescription}
        />
      )}
      {isPixSelected && (
        <PaymentButton
          ariaLabel="Pagar com pix"
          IconComponent={<Icon component={Pix} height="18" width="22" />}
          paymentName={paymentName}
          paymentDescription={paymentDescription}
        />
      )}
      {isOnlineSelected && (
        <PaymentButton
          ariaLabel="Pagar pelo site"
          IconComponent={
            <PaymentImage
              src={brandImageURL}
              alt={paymentMethod?.method.description}
            />
          }
          paymentName={paymentName}
          paymentDescription={paymentDescription}
        />
      )}
      {isOfflineSelected && (
        <PaymentButton
          ariaLabel="Pagar na entrega ou retirada"
          IconComponent={
            <PaymentImage
              src={brandImageURL}
              alt={paymentMethod?.method.description}
            />
          }
          paymentName={paymentName}
          paymentDescription={paymentDescription}
        />
      )}
      <S.SwitchButton onClick={onSwitch} variant="primary-inverted">
        {paymentMethod ? "Trocar" : "Escolher"}
      </S.SwitchButton>
    </Flex>
  );
};

const PaymentButton: React.FC<{
  ariaLabel: string;
  IconComponent: React.ReactNode;
  paymentName: string;
  paymentDescription: string;
}> = ({ ariaLabel, IconComponent, paymentName, paymentDescription }) => (
  <S.PaymentTypeButton aria-label={ariaLabel}>
    {IconComponent}
    <S.Container>
      {paymentName}
      <S.PaymentTypeDescripton>{paymentDescription}</S.PaymentTypeDescripton>
    </S.Container>
  </S.PaymentTypeButton>
);

const PaymentImage: React.FC<{ src: string; alt: string }> = ({ src, alt }) => (
  <NextImage height={18} width={22} src={src} alt={alt} />
);
