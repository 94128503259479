import { Item, ItemJSON } from "@app/domains/checkout/models";
import { useMerchant } from "@app/domains/merchant/context";
import { useMemo, useState } from "react";
import createPersistedState from "use-persisted-state";

const CURRENT_BAG_ITEMS_KEY = "current_bagItems";

export function usePersistedItems(): [
  Item[] | undefined,
  (items: Item[]) => void,
] {
  const { merchant } = useMerchant();

  const useLocalCurrentItems = useMemo(
    () =>
      createPersistedState<ItemJSON[]>(
        `${merchant.id}.${CURRENT_BAG_ITEMS_KEY}`,
      ),
    [merchant],
  );

  const [localCurrentItems, setLocalCurrentItems] = useLocalCurrentItems();
  const [persistedItems, setPersistedItems] = useState<Item[] | undefined>(
    () => {
      if (!localCurrentItems) {
        return undefined;
      }

      return localCurrentItems.map((item: ItemJSON) => Item.fromJSON(item));
    },
  );

  function handleSetPersistedItems(items: Item[]) {
    const itemsJSON = items.map((item) => item.toJSON());
    setLocalCurrentItems(itemsJSON);
    setPersistedItems(items);
  }

  return [persistedItems, handleSetPersistedItems];
}
