import { Flex } from "@app/domains/shared/design-system";
import styled from "styled-components";

export const errorPaymentMethodsMinHeight = "112px";

export const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: ${errorPaymentMethodsMinHeight};
`;
