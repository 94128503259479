import { Flex } from "@app/domains/shared/design-system";
import styled, { keyframes, css } from "styled-components";

export const Container = styled(Flex)`
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.space.regular};
`;

export const Progress = styled.div`
  width: 50%;
  height: 3px;

  background-color: ${({ theme }) => theme.colors.grayLight};
  border-radius: ${({ theme }) => theme.radii.m};

  &:first-child {
    width: 25%;
    margin-right: 2px;
  }

  &:last-child {
    width: 25%;
    margin-left: 2px;
  }
`;

const progressAnimation = (width: string) => keyframes`
  0% {
    width: ${width};
  }
  99% {
    width: 100%;
  }
  100% {
    width: ${width};
  }
`;

export const ProgressBar = styled.div<{ width: string }>`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.positive};
  border-radius: ${({ theme }) => theme.radii.m};
  ${({ width }) =>
    width !== "0%"
      ? css`
          animation: ${progressAnimation(width)} 2s infinite ease-in-out;
        `
      : css`
          width: 0;
        `}
`;
