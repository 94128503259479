import React, { useEffect, useState } from "react";

import * as S from "./styles";
import {
  Button,
  Heading,
  Icon,
  snackbar,
  Text,
} from "@ifood/pomodoro-components";
import { ChevronLeft, ChevronRight } from "@ifood/pomodoro-icons";
import { useMerchant } from "@app/domains/merchant/context";
import { useIsMobile } from "@app/domains/shared/hooks";
import { useRouter } from "next/router";

import axios from "axios";
import Link from "next/link";
import { Customer } from "@app/domains/authentication/models";
import { useAuthentication } from "@app/domains/authentication/context";

export const DeleteAccount: React.VFC = () => {
  const { logout, customer } = useAuthentication();
  const { merchant } = useMerchant();
  const isMobile = useIsMobile();
  const router = useRouter();

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const toogleConfirmDialog = () => setConfirmDialogOpen(!confirmDialogOpen);

  const [failedDialogOpen, setFailedDialogOpen] = useState(false);
  const toogleFailedDialog = () => setFailedDialogOpen(!failedDialogOpen);

  useEffect(() => {
    if (!customer) handleRedirectToHome();
  }, [customer]);

  function handleRedirectToHome() {
    router.push(`/${merchant.query}`);
  }

  function handleRedirectToOrders() {
    router.push(`/${merchant.query}/order`);
    toogleFailedDialog();
  }

  async function handleDeleteAccount() {
    try {
      toogleConfirmDialog();
      snackbar({ message: "Excluindo conta...", variant: "neutral" });
      await Customer.deleteCustomer();
      snackbar({ message: "Conta excluída", variant: "success" });
      logout();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const { code } = error.response?.data || {};
        if (code === "NOT_CONCLUDED_ORDERS") {
          return toogleFailedDialog();
        }
      }
      snackbar({ message: "Falha ao excluir conta", variant: "error" });
    }
  }

  const Dialog = isMobile ? S.Drawer : S.Dialog;

  return (
    <S.Wrapper>
      <S.BreadcrumbMobile>
        <Icon
          size="s"
          component={ChevronLeft}
          onClick={() => router.push(`/${merchant.query}`)}
        />
        <Text>Excluir conta</Text>
      </S.BreadcrumbMobile>

      <S.BreadcrumbDesktop>
        <Link href={`/${merchant.query}`}>Home</Link>
        <Icon size="xs" component={ChevronRight} />
        <Link href={router.asPath}>Excluir conta</Link>
      </S.BreadcrumbDesktop>

      <S.Content>
        <Heading as="h1" color="#3E3E3E" mb="0">
          Excluir conta
        </Heading>

        <Text color="#3E3E3E">
          Ao excluir sua conta, todos seus dados dados serão apagados
          definitivamente. Será necessário criar uma nova conta caso deseje
          voltar.
        </Text>

        <S.Buttons>
          <Button variant="secondary" onClick={handleRedirectToHome}>
            Cancelar
          </Button>
          <Button onClick={toogleConfirmDialog}>Excluir conta</Button>
        </S.Buttons>
      </S.Content>

      <Dialog open={confirmDialogOpen} onClose={toogleConfirmDialog}>
        <S.Dialog.Header>
          <Heading>Quer mesmo excluir sua conta?</Heading>
        </S.Dialog.Header>
        <S.Dialog.Body>
          <Text>
            Essa ação é definitiva. Ao excluir sua conta, você perderá seu
            acesso e suas informações serão apagadas. Será necessário criar uma
            nova conta caso deseje voltar.
          </Text>
        </S.Dialog.Body>
        <S.Dialog.Footer>
          {!isMobile && (
            <Button variant="secondary" onClick={toogleConfirmDialog}>
              Cancelar
            </Button>
          )}
          <Button onClick={handleDeleteAccount}>Sim, excluir</Button>
        </S.Dialog.Footer>
      </Dialog>

      <Dialog open={failedDialogOpen} onClose={toogleFailedDialog}>
        <S.Dialog.Header>
          <Heading>Não foi possível excluir a conta</Heading>
        </S.Dialog.Header>
        <S.Dialog.Body>
          <Text>
            Existe um pedido em aberto e por isso não foi possível excluir a sua
            conta no momento. Tente novamente após conclusão do pedido.
          </Text>
        </S.Dialog.Body>
        <S.Dialog.Footer>
          <Button onClick={handleRedirectToOrders}>Acessar pedidos</Button>
        </S.Dialog.Footer>
      </Dialog>
    </S.Wrapper>
  );
};
