import { Icon } from "@app/domains/shared/design-system";
import styled, { DefaultTheme } from "styled-components";

export const StarCircle = styled(Icon)`
  position: relative;

  transform: scale(0.7);

  &:after {
    position: absolute;

    width: 100%;
    height: 100%;

    background-color: ${(props) => props.theme.colors.secondary};
    border-radius: 100%;
    transform: scale(1.3);
    opacity: 0.3;

    content: "";
  }
`;

export const SearchWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.regular};
  padding: ${({ theme }) => theme.space.regular};
`;

const belowDesktop = ({ theme }: { theme: DefaultTheme }) =>
  `max-width: ${theme.screens.m.max}`;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.looser};

  @media (${belowDesktop}) {
    gap: ${({ theme }) => theme.space.regular};
  }
`;

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.largest};

  @media (${belowDesktop}) {
    gap: ${({ theme }) => theme.space.larger};
  }
`;
