import { createContext, useState } from 'react';

import { orderAboyeur } from "../events";
import { Order, OrderResponse, Orders, Question } from '../models';

type SetOrder = Orders | ((prevOrder: Orders | undefined) => Orders);

type PixDrawerStatus = {
  order: Order | undefined;
  isOpen: boolean;
};

type FAQDrawerStatus = {
  isListOpen: boolean;
  isQuestionOpen: boolean;
  currentQuestion: Question | undefined;
};

export type OrderContextValue = {
  orders: Orders | undefined;
  pixDrawerStatus: PixDrawerStatus;
  updateOrders: (setOrder: SetOrder) => void;
  openPixDrawer: (order: Order) => void;
  closePixDrawer: () => void;
  isHelpDrawerOpen: boolean;
  openHelpDrawer: () => void;
  closeHelpDrawer: () => void;
  openOrderDetails: (order: Order) => void;
  closeOrderDetails: () => void;
  selectedOrder: Order | undefined;
  FAQDrawerStatus: FAQDrawerStatus;
  openFAQDrawer: () => void;
  closeFAQDrawer: () => void;
  openQuestionDrawer: (question: Question) => void;
  closeQuestionDrawer: () => void;
  closeFAQAndQuestionDrawers: () => void;
};

export const OrderContext = createContext<OrderContextValue | undefined>(undefined);

export type OrderProviderProps = {
  ordersResponse?: OrderResponse[];
};

const initialPixDrawerStatus = {
  order: undefined,
  isOpen: false,
};

const initialFAQDrawerStatus = {
  isListOpen: false,
  isQuestionOpen: false,
  currentQuestion: undefined,
};

export const OrderProvider: React.FC<OrderProviderProps> = ({ children, ordersResponse }) => {
  const [context, setContext] = useState<OrderContextValue>(() => {
    return {
      openPixDrawer,
      closePixDrawer,
      pixDrawerStatus: initialPixDrawerStatus,
      updateOrders: onUpdateOrders,
      orders: ordersResponse && ordersResponse.length > 0 ? Orders.fromApi(ordersResponse) : undefined,
      isHelpDrawerOpen: false,
      openHelpDrawer,
      closeHelpDrawer,
      selectedOrder: undefined,
      openOrderDetails,
      closeOrderDetails,
      FAQDrawerStatus: initialFAQDrawerStatus,
      openFAQDrawer,
      closeFAQDrawer,
      openQuestionDrawer,
      closeQuestionDrawer,
      closeFAQAndQuestionDrawers,
    };
  });

  function onUpdateOrders(setOrder: SetOrder) {
    setContext(({ orders: prevOrders, ...prevContext }) => {
      const newOrders = typeof setOrder === 'function' ? setOrder(prevOrders) : setOrder;

      return {
        ...prevContext,
        orders: newOrders,
      };
    });
  }

  function openPixDrawer(order: Order) {
    orderAboyeur.events.pix.openModal();

    setContext((prevContext) => ({
      ...prevContext,
      pixDrawerStatus: {
        order,
        isOpen: true,
      },
    }));
  }

  function closePixDrawer() {
    orderAboyeur.events.pix.closeModal();

    setContext((prevContext) => ({
      ...prevContext,
      pixDrawerStatus: initialPixDrawerStatus,
    }));
  }

  function openHelpDrawer() {
    orderAboyeur.events.help.openModal();

    setContext((prevContext) => ({
      ...prevContext,
      isHelpDrawerOpen: true,
    }));
  }

  function closeHelpDrawer() {
    orderAboyeur.events.help.closeModal();

    setContext((prevContext) => ({
      ...prevContext,
      isHelpDrawerOpen: false,
    }));
  }

  function openOrderDetails(order: Order) {
    orderAboyeur.events.orderDetails.open();
    setContext((prevContext) => ({
      ...prevContext,
      selectedOrder: order,
    }));
  }

  function closeOrderDetails() {
    orderAboyeur.events.orderDetails.close();
    setContext((prevContext) => ({
      ...prevContext,
      selectedOrder: undefined,
    }));
  }

  function openFAQDrawer() {
    orderAboyeur.events.FAQs.openModal();
    setContext((prevContext) => ({
      ...prevContext,
      FAQDrawerStatus: {
        ...prevContext.FAQDrawerStatus,
        isListOpen: true,
      },
    }));
  }

  function closeFAQDrawer() {
    orderAboyeur.events.FAQs.closeModal();
    setContext((prevContext) => ({
      ...prevContext,
      FAQDrawerStatus: {
        ...prevContext.FAQDrawerStatus,
        isListOpen: false,
      },
    }));
  }

  function openQuestionDrawer(question: Question) {
    orderAboyeur.events.FAQ.openModal(question.title);
    setContext((prevContext) => ({
      ...prevContext,
      FAQDrawerStatus: {
        ...prevContext.FAQDrawerStatus,
        isQuestionOpen: true,
        currentQuestion: question,
      },
    }));
  }

  function closeQuestionDrawer() {
    orderAboyeur.events.FAQ.closeModal();
    setContext((prevContext) => ({
      ...prevContext,
      FAQDrawerStatus: {
        ...prevContext.FAQDrawerStatus,
        isQuestionOpen: false,
        currentQuestion: undefined,
      },
    }));
  }

  function closeFAQAndQuestionDrawers() {
    closeQuestionDrawer();
    closeFAQDrawer();
  }

  return <OrderContext.Provider value={context}>{children}</OrderContext.Provider>;
};

OrderProvider.displayName = 'OrderProvider';
