import { useMerchant } from "@app/domains/merchant/context";
import { Flex, Text } from "@ifood/pomodoro-components";
import { ImageWithFallback } from "@app/domains/shared/design-system";
import * as S from "./styles";

export const MerchantInfo: React.VFC = () => {
  const { merchant } = useMerchant();

  const logo = merchant.getLogoUrl();

  return (
    <S.Wrapper>
      <S.Image>
        <ImageWithFallback
          src={logo}
          fallbackSrc="/images/no_image.png"
          layout="fill"
          objectFit="contain"
          alt={merchant.name}
        />
      </S.Image>
      <Flex flexDirection="column" gap="2px">
        <S.MerchantName color="black">{merchant.name}</S.MerchantName>
        <Text fontSize="small" color="grayDarkest" lineHeight="16px">
          Pedido mínimo <b>{merchant.minimumOrderValue.format()}</b>
        </Text>
      </Flex>
    </S.Wrapper>
  );
};
