import { useAuthentication } from "@app/domains/authentication/context";
import { useMerchant } from "@app/domains/merchant/context";
import { useQuery } from "react-query";

import { orderAboyeur } from "../events";
import { useOrder } from "../context";
import { Orders } from "../models";

async function getOrdersList(id: string) {
  const orders = await Orders.getOrders(id);
  return Orders.fromApi(orders);
}

export const useOrdersListQuery = () => {
  const { updateOrders } = useOrder();
  const { merchant } = useMerchant();
  const { customer } = useAuthentication();

  return useQuery(["orders", merchant.id], () => getOrdersList(merchant.id), {
    retry: 4,
    retryDelay: 1500,
    refetchOnWindowFocus: false,
    enabled: Boolean(customer),
    onError: (error: any) => {
      orderAboyeur.events.catch.onError(error);
    },
    onSuccess: (data) => {
      updateOrders(data);
    },
  });
};
