import { Text } from "@ifood/pomodoro-components";
import styled, { DefaultTheme } from "styled-components";

const belowDesktop = ({ theme }: { theme: DefaultTheme }) =>
  `max-width: ${theme.screens.m.max}`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.regular};
  padding-left: ${({ theme }) => theme.space.regular};
  padding-right: ${({ theme }) => theme.space.regular};

  @media (${belowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.l};
    line-height: ${({ theme }) => theme.fontSizes.m};
  }
`;

export const CategoryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: ${({ theme }) => theme.space.regular};
`;

type CategoryCardProps = {
  backgroundColor?: string;
};

export const CategoryCard = styled.div<CategoryCardProps>`
  display: flex;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "#e6e6e6"};
  border-radius: 8px;
  position: relative;
`;

export const CategoryName = styled(Text)`
  display: flex;
  border-radius: 8px;
  position: absolute;
  top: 10px;
  left: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  width: 55%;
`;

export const ImageContainer = styled.div`
  width: 43%;
  border-radius: 50%;
  overflow: hidden;
  aspect-ratio: 1;
  margin-left: auto;
  cursor: pointer;

  & span {
    width: 100% !important;
    height: 100% !important;
  }

  &:hover img {
    transition: all 100ms;
    scale: 1.1;
  }
`;
