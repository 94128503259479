import { useMerchant } from "@app/domains/merchant/context";
import { usePaymentMethods } from "@app/domains/checkout/hooks";
import { PageFooter } from "@app/domains/shared/page-wrapper";

export const Footer: React.VFC = () => {
  const { merchant } = useMerchant();
  const { paymentMethods } = usePaymentMethods();
  const socialMediaLinks = merchant.getSocialMediaLinks();
  const institutionalLinks = merchant.getInstitutionalLink();

  return (
    <PageFooter
      socialMediaLinks={socialMediaLinks}
      institutionalLinks={institutionalLinks}
      paymentMethods={paymentMethods}
      merchant={merchant}
    />
  );
};
