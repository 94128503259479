import { Drawer } from "@app/domains/shared/design-system";
import { useOrder } from "@app/domains/order/context";

import * as S from "./styles";

export function FAQQuestion() {
  const { FAQDrawerStatus, closeQuestionDrawer, closeFAQAndQuestionDrawers } =
    useOrder();
  const { currentQuestion } = FAQDrawerStatus;
  return (
    <Drawer open title="Ajuda" onClose={closeQuestionDrawer}>
      <S.Close onClick={closeFAQAndQuestionDrawers}>Fechar</S.Close>
      <S.Body>
        <S.Heading as="h2">{currentQuestion.title}</S.Heading>
        <div dangerouslySetInnerHTML={currentQuestion.content}></div>
      </S.Body>
    </Drawer>
  );
}
