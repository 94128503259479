import { checkoutAboyeur } from "@app/domains/checkout/events";
import { CardTokenResponse } from "@app/domains/checkout/models";
import { Loading, Text } from "@app/domains/shared/design-system";
import { getQueryParams } from "@app/domains/shared/url-utils";
import { useRouter } from "next/router";
import { useEffect } from "react";

import { useCardTokens, usePaymentMethods } from "@app/domains/checkout/hooks";
import { getBrandIconPath } from "@app/domains/shared/image-utils";

import * as S from "./styles";
import { Button, Flex, Icon } from "@ifood/pomodoro-components";
import { TrashFilled } from "@ifood/pomodoro-icons";

type SelectOnlinePaymentCardProps = {
  onBack: () => void;
  onEmptyList: () => void;
  onAddCard: () => void;
  onDeleteButtonClick: (cardToken: CardTokenResponse) => void;
  onSelectCard: (cardToken: CardTokenResponse) => void;
};

export const SelectOnlinePaymentCard: React.VFC<
  SelectOnlinePaymentCardProps
> = ({ onBack, onEmptyList, onAddCard, onDeleteButtonClick, onSelectCard }) => {
  const router = useRouter();
  const { cardTokens, isLoading } = useCardTokens();
  const { paymentMethods } = usePaymentMethods();

  const acceptedOnlinePaymentCardBrands = paymentMethods
    ? paymentMethods
        .getOnlineCardsPayments()
        .map((paymentMethod) => paymentMethod.brand.id)
    : [];

  const handleSelectCard = (cardToken: CardTokenResponse) => {
    const isCardAcceptedByMerchant = acceptedOnlinePaymentCardBrands.includes(
      cardToken.brand.id,
    );
    if (isCardAcceptedByMerchant) onSelectCard(cardToken);
  };

  const handleDeleteCard = (
    e: React.MouseEvent,
    cardToken: CardTokenResponse,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    onDeleteButtonClick(cardToken);
  };

  useEffect(() => {
    const pageParams = getQueryParams();

    if (isLoading) return;

    if (!cardTokens.length) {
      onEmptyList();
      return;
    }

    if (getQueryParams().get("success") !== "true") return;

    const lastAddedCardToken = cardTokens[cardTokens.length - 1];

    pageParams.delete("success");

    const pathnameWithoutReturnStatus = `${
      window.location.pathname
    }?${pageParams.toString()}`;
    router.replace(pathnameWithoutReturnStatus, undefined, { shallow: true });

    handleSelectCard(lastAddedCardToken);
  }, [cardTokens, onEmptyList, isLoading]);

  function addNewCard() {
    checkoutAboyeur.events.payment.cardRegistrationLink();
    onAddCard();
  }

  return (
    <S.Wrapper>
      <S.Cards>
        {isLoading && (
          <Loading variant="large" color="primary">
            Carregando...
          </Loading>
        )}
        {cardTokens?.map((cardToken) => {
          const brandIcon = getBrandIconPath(cardToken.brand.name);
          const isCardAcceptedByMerchant =
            acceptedOnlinePaymentCardBrands.includes(cardToken.brand.id);

          return (
            <S.CardTokenInfo
              key={cardToken.id}
              onClick={() => handleSelectCard(cardToken)}
              disabled={!isCardAcceptedByMerchant}
            >
              <Flex alignItems="center" gap="regular">
                <S.BrandIcon
                  width={28}
                  height={20}
                  src={
                    isCardAcceptedByMerchant
                      ? brandIcon
                      : "/icons/payment/default.svg"
                  }
                />
                <S.CardInfoText>
                  <Text
                    lineHeight="20px"
                    fontWeight="500"
                    fontSize="14px"
                    color={isCardAcceptedByMerchant ? "black" : "gray"}
                  >
                    {cardToken.method.description}
                  </Text>
                  <Text color="grayDarker" lineHeight="20px" fontSize="14px">
                    {`${cardToken.brand.description} •••• ${cardToken.last_four_digits}`}
                  </Text>
                </S.CardInfoText>
              </Flex>
              <S.Actions>
                <S.DeleteCardButton
                  onClick={(e) => handleDeleteCard(e, cardToken)}
                >
                  <Icon component={TrashFilled}></Icon>
                </S.DeleteCardButton>
                {!isCardAcceptedByMerchant && (
                  <Text variant="small" color="grayDarker">
                    Não aceito nesta loja
                  </Text>
                )}
              </S.Actions>
            </S.CardTokenInfo>
          );
        })}
      </S.Cards>
      <S.BottomButtons>
        <Button color="primary" variant="primary-inverted" onClick={onBack}>
          Voltar
        </Button>
        <Button onClick={addNewCard}>Adicionar cartão</Button>
      </S.BottomButtons>
    </S.Wrapper>
  );
};
