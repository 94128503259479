import { useCatalog } from "@app/domains/catalog/context";
import { Category } from "@app/domains/catalog/models";
import { Header } from "@app/domains/catalog/views";
import * as S from "./styles";
import { Flex } from "@ifood/pomodoro-components";
import NextImage from "next/image";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { catalogAboyeur } from "@app/domains/catalog/events";
import { Carousel } from "@app/domains/shared/design-system";
import { useMerchant } from "@app/domains/merchant/context";

export const CarouselCategory: React.VFC = () => {
  const { catalog } = useCatalog();
  const { merchant } = useMerchant();
  const router = useRouter();

  useEffect(() => {
    catalogAboyeur.events.category.viewAisleScreen();
  }, []);

  const categories = catalog
    .getVisibleCategories()
    .filter((category) => category.logoUrl);

  const categoryLogoUrl = (src: string) =>
    `https://static-images.ifood.com.br${src}`;

  const handleClick = (category: Category) => {
    router.push(`/${merchant.query}/categoria/${category.code}`);
  };

  if (categories.length == 0) return null;

  return (
    <S.Container>
      <Header mb={{ m: "large", _: "largest" }} name={"Categorias"} />
      <Carousel
        gap="clamp(15px, 2.5dvw, 48px)"
        simultaneousItems={9}
        mobileWidth="72px"
      >
        {categories.map((category) => {
          return (
            <S.CategoryItem
              key={category.code}
              onClick={() => handleClick(category)}
            >
              <Flex flexDirection="column" width="100%">
                <S.ImageContainer>
                  <NextImage
                    src={categoryLogoUrl(category.logoUrl)}
                    alt={category.name}
                    width="100%"
                    height="100%"
                  />
                </S.ImageContainer>
              </Flex>
              <S.Text>{category.name}</S.Text>
            </S.CategoryItem>
          );
        })}
      </Carousel>
    </S.Container>
  );
};
