import { Flex } from "@app/domains/shared/design-system";
import styled from "styled-components";

export const Container = styled(Flex)`
  position: relative;

  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.space.larger};
`;
