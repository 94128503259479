import { ItemDetails } from "../itemDetails";
import { byOrder } from "@app/domains/catalog/utils/utils";
import * as S from "./styles";
import Link from "next/link";
import { useRouter } from "next/router";
import { Flex, Icon } from "@ifood/pomodoro-components";
import { Bag, ChevronLeft } from "@ifood/pomodoro-icons";
import { useCheckout } from "@app/domains/checkout/context";
import { Header } from "@app/domains/catalog/views";
import { useCatalog } from "@app/domains/catalog/context";
import { Carousel } from "@app/domains/shared/design-system";
import { SmallItem } from "../SmallItem";
import { useMerchant } from "@app/domains/merchant/context";

export const ItemPage: React.VFC = () => {
  const { setCheckoutDetailsStatus } = useCheckout();
  const { catalog } = useCatalog();
  const { merchant } = useMerchant();
  const router = useRouter();

  const allItems = catalog.getAllItems();
  const item = allItems.find((item) => item.code == router.query.itemCode);

  if (!item) return null;

  const relatedItems = allItems
    .filter((relatedItem) => relatedItem.code !== item.code)
    .sort(byOrder);

  const handleOpenBag = () => setCheckoutDetailsStatus("OPEN");

  return (
    <S.Container>
      <S.TopGrid>
        <S.BreadcrumbDesktop>
          <Link href={`/${merchant.query}`}>Home</Link>
          <Flex>{">"}</Flex>
          <Link href={`/${merchant.query}/categoria/${item.categoryCode}`}>
            {item.category}
          </Link>
          <Flex>{">"}</Flex>
          <Link href={router.asPath}>{item.description}</Link>
        </S.BreadcrumbDesktop>

        <S.BreadcrumbMobile>
          <Icon
            size="s"
            component={ChevronLeft}
            onClick={() =>
              router.push(`/${merchant.query}/categoria/${item.categoryCode}`)
            }
          />
          <S.BagButton onClick={handleOpenBag}>
            <Icon width="16px" height="16px" component={Bag} />
          </S.BagButton>
        </S.BreadcrumbMobile>
      </S.TopGrid>

      <ItemDetails item={item}></ItemDetails>

      {Boolean(relatedItems.length) && (
        <S.Carousel>
          <Header
            mb={"48px"}
            name={"Peça também"}
            subtitle={"Itens relacionados"}
            route={`/${merchant.query}/categoria/${item.categoryCode}`}
          />
          <Carousel gap="clamp(15px, 2.5dvw, 32px)" simultaneousItems={7}>
            {relatedItems.map((item) => {
              return <SmallItem key={item.code} item={item} />;
            })}
          </Carousel>
        </S.Carousel>
      )}
    </S.Container>
  );
};
