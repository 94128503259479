import { accountAboyeur } from "@app/domains/account/events";
import { createContext, useContext, useMemo, useState } from "react";

type AccountContext = {
  isProfileDrawerOpen: boolean;
  openProfileDrawer: () => void;
  closeProfileDrawer: () => void;
};

export const AccountContext = createContext<AccountContext | undefined>(
  undefined,
);

// eslint-disable-next-line @typescript-eslint/ban-types
export type AccountProviderProps = {};

export const AccountProvider: React.FC<AccountProviderProps> = ({
  children,
}) => {
  const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);

  function handleOpenProfileDrawer() {
    setIsProfileDrawerOpen(true);
    accountAboyeur.events.profile.open();
  }

  function handleCloseProfileDrawer() {
    setIsProfileDrawerOpen(false);
    accountAboyeur.events.profile.close();
  }

  const contextValue: AccountContext = useMemo(
    () => ({
      isProfileDrawerOpen,
      openProfileDrawer: handleOpenProfileDrawer,
      closeProfileDrawer: handleCloseProfileDrawer,
    }),
    [isProfileDrawerOpen],
  );

  return (
    <AccountContext.Provider value={contextValue}>
      {children}
    </AccountContext.Provider>
  );
};

AccountProvider.displayName = "AccountProvider";

export function useAccount(): AccountContext {
  const context = useContext(AccountContext);

  if (typeof context === "undefined") {
    throw new Error(
      `'useAccount()' must be used within a ${AccountProvider.displayName}`,
    );
  }

  return context;
}
