import { useMerchant } from "@app/domains/merchant/context";

import * as S from "./styles";

export const MinimumOrder: React.VFC = () => {
  const { merchant } = useMerchant();

  if (!merchant.minimumOrderValue) return null;

  return (
    <S.Text color="grayDarkest">
      Pedido mínimo: <b>{merchant.minimumOrderValue.format()}</b>
    </S.Text>
  );
};
