import styled, { DefaultTheme } from "styled-components";
import { Divider as PomodoroDivider } from "@app/domains/shared/design-system";
import { Button, Flex, Text } from "@ifood/pomodoro-components";

const belowDesktop = ({ theme }: { theme: DefaultTheme }) =>
  `max-width: ${theme.screens.m.max}`;

export const UnavailableBox = styled(Flex)`
  flex-direction: column;
  border: solid 1px #ebebeb;
  gap: ${({ theme }) => theme.space.regular};
  border-radius: ${({ theme }) => theme.space.smaller};

  padding: ${({ theme }) => theme.space.small};
  margin: -1px -${({ theme }) => theme.space.small};
  @media (${belowDesktop}) {
    padding: ${({ theme }) => theme.space.smaller};
    margin: -1px -${({ theme }) => theme.space.smaller};
  }

  padding-bottom: ${({ theme }) => theme.space.none} !important;
  margin-bottom: ${({ theme }) => theme.space.small} !important;
`;

export const WarnBox = styled(Flex)`
  background-color: #f5f5f5;
  padding: ${({ theme }) => theme.space.small};
  gap: ${({ theme }) => theme.space.small};
  border-radius: ${({ theme }) => theme.space.smaller};
  align-items: center;
`;

export const WarnIcon = styled.div`
  position: relative;
  width: 14px;
  height: 14px;
  border: solid 2px #666666;
  border-radius: 50%;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #666666;
    transform: translateY(-50%) rotate(-45deg);
  }
`;

export const WarnMessageArea = styled(Flex)`
  gap: ${({ theme }) => theme.space.smallest};
  flex-direction: column;
`;

export const WarnTitle = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.tsm};
  line-height: ${({ theme }) => theme.fontSizes.s};
  font-weight: 500;
  color: #666666;
`;

export const WarnDescription = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: ${({ theme }) => theme.fontSizes.s};
  color: #666666;
`;

export const ItemsWrapper = styled(Flex)`
  gap: ${({ theme }) => theme.space.smaller};
  flex-direction: column;
`;

export const Divider = styled(PomodoroDivider)`
  margin: ${({ theme }) => theme.space.none};
  border-color: #ebebeb;
`;

export const RemoveAllButton = styled(Button)`
  padding: ${({ theme }) => theme.space.none};
  gap: ${({ theme }) => theme.space.smaller};
  display: flex;
  color: #eb0033;
  margin-left: auto;
  width: fit-content;
  align-items: center;

  svg {
    width: 12px;
    height: 12px;
    padding: 4px;
    color: white;
    border-radius: 50%;
    background: #eb0033;
  }

  font-size: ${({ theme }) => theme.fontSizes.tsm};
  line-height: ${({ theme }) => theme.fontSizes.tsm};

  @media (${belowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.xs};
    line-height: ${({ theme }) => theme.fontSizes.xs};
  }
`;
