import { Z_INDEXES } from "@app/domains/shared/constants";
import { DrawerWithPush } from "@app/domains/shared/design-system";

import { Title } from "./Title";

export const Mobile = ({ open, onClose, children }) => {
  return (
    <DrawerWithPush
      open={open}
      title={<Title />}
      onClose={onClose}
      zIndex={Z_INDEXES.RESEND_CODE_DRAWER}
    >
      {children}
    </DrawerWithPush>
  );
};
