import { getFingerprintSessionId } from "@app/domains/checkout/shared/fingerprint-utils";
import { Money } from "@app/domains/shared/models";

import { CardTokenResponse, DeliveredMethodTag } from ".";

export type Type = {
  name: "ONLINE" | "OFFLINE";
  description: string;
};

export type Source = "CARD_TOKEN" | "OFFLINE" | "TOKEN";

export type Method = {
  name: string;
  description: string;
};

export type Brand = {
  id: string;
  name: string;
  regex: string;
  description: string;
};

export type MovilePayAllowedBrand = {
  type: string;
  method: string;
  brand_id: string;
};

export type MovilePayAllowedBrands = MovilePayAllowedBrand[];

export type AdditionalData = {
  google_pay_gateway?: string;
  google_pay_merchant?: string;
  movile_pay_allowed_brands?: MovilePayAllowedBrands;
};
export type Provider = {
  name: string;
  zero_dollar: boolean;
  test: boolean;
  url: string;
  revalidation_url: string;
  public_key: string;
  acquirers: string;
};

export type TokenConfiguration = {
  id: string;
  providers: Provider[];
};

export type CardValidation = {
  isLoading: boolean;
  isValid: boolean;
  error?: {
    cardValidationResponse: {
      data: Record<string, unknown>;
      message: Record<string, unknown>;
    };
  };
};

export type CheckoutPaymentMethod = {
  id: string;
  name: string;
  type: Type;
  method: Method;
  liability: string;
  brand: Brand;
  acceptChange?: boolean;
  additionalData: Record<string, unknown>;
  token_configuration: TokenConfiguration;
};

export type Details = {
  changeFor: {
    value: number;
    currency: string;
  };
};

export type CheckoutPaymentSource = {
  source: string;
  paymentMethod: CheckoutPaymentMethod;
  amount: {
    value: number;
    currency: string;
  };
  additionalData: object;
  details?: Details;
  cardToken?: {
    id: string;
    cvv?: string;
    bin: string;
  };
};

export type CheckoutPaymentSources = {
  sources: Array<CheckoutPaymentSource>;
  contextInfo: {
    tags: string;
    cybersourceFingerprint: string;
    clearsaleFingerprint: string;
  };
};

export type PaymentMethodItemResponse = {
  id: string;
  name: string;
  type: Type;
  method: Method;
  liability: string;
  brand: Brand;
  additional_data: AdditionalData;
  required_cvv: boolean;
  token_configuration: TokenConfiguration;
};

export type PaymentMethodResponse = PaymentMethodItemResponse[];

export class PaymentMethod {
  static fromApi(rawPaymentMethod: PaymentMethodItemResponse) {
    return new PaymentMethod(
      rawPaymentMethod.id,
      rawPaymentMethod.brand,
      rawPaymentMethod.name,
      rawPaymentMethod.type,
      rawPaymentMethod.method,
      rawPaymentMethod.liability,
      rawPaymentMethod.additional_data,
      rawPaymentMethod.token_configuration,
      rawPaymentMethod.required_cvv,
    );
  }

  static canUseThisBrand(brand: string) {
    return !["IFOOD_MEAL_VOUCHER", "TICKET"].includes(brand);
  }

  constructor(
    public id: string,
    public brand: Brand,
    public name: string,
    public type: Type,
    public method: Method,
    public liability: string,
    public additional_data: AdditionalData,
    public token_configuration: TokenConfiguration,
    public required_cvv: boolean,
    public description: string = "",
    public code: string = "",
    public debit: boolean = false,
    public cardValidation: CardValidation = {
      isLoading: false,
      isValid: false,
    },
    public cashChange?: Money,
  ) {}

  withCashChange(money?: Money) {
    this.cashChange = money;

    return this;
  }

  toPayload(
    deliveredMethodTag: DeliveredMethodTag,
    amount: Money,
    cardToken?: CardTokenResponse,
  ): CheckoutPaymentSources {
    const hasCashChange = Boolean(
      this.method.name === "CASH" && this.cashChange,
    );
    const fingerprintSessionId = getFingerprintSessionId();

    return {
      sources: [
        {
          source: this.sourceToCheckout(),
          paymentMethod: {
            id: this.id,
            name: this.name,
            type: this.type,
            method: this.method,
            liability: this.liability,
            brand: this.brand,
            token_configuration: this.token_configuration,
            acceptChange: hasCashChange ? true : undefined,
            additionalData: {},
          },
          amount: {
            value: amount.getValue(),
            currency: amount.currency,
          },
          additionalData: {},
          details: hasCashChange
            ? {
                changeFor: {
                  value: (this.cashChange as Money).getValue(),
                  currency: (this.cashChange as Money).currency,
                },
              }
            : undefined,
          cardToken: cardToken
            ? {
                id: cardToken.id,
                cvv: cardToken.cvv,
                bin: cardToken.bin,
              }
            : undefined,
        },
      ],
      contextInfo: {
        tags: deliveredMethodTag,
        cybersourceFingerprint: fingerprintSessionId,
        clearsaleFingerprint: fingerprintSessionId,
      },
    };
  }

  sourceToCheckout(): Source {
    if (this.isOffline()) {
      return "OFFLINE";
    }

    return this.isPix() ? "TOKEN" : "CARD_TOKEN";
  }

  isOffline() {
    return this.type.name === "OFFLINE";
  }

  isOnline() {
    return this.type.name === "ONLINE";
  }

  isPix() {
    return this.method.name === "PIX";
  }

  isMealVoucher() {
    return this.method.name === "MEAL_VOUCHER";
  }

  isBankDraft() {
    return this.method.name === "BANK_DRAFT";
  }

  isDebit() {
    return this.method.name === "DEBIT";
  }

  isCredit() {
    return this.method.name === "CREDIT";
  }

  isCash() {
    return this.method.name === "CASH";
  }

  hasTicketBrand() {
    return this.brand.name === "TICKET";
  }

  isVisible() {
    return PaymentMethod.canUseThisBrand(this.brand.name);
  }

  getLabel() {
    if (this.isPix()) return "Pix";
    if (this.isCash()) return "Dinheiro";
    if (this.isBankDraft()) return "Cheque";
    return `${this.method.description} - ${this.brand.description}`.replace(
      "EXTERNAL",
      "Cartão da loja",
    );
  }
}
