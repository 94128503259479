import { Flex } from "@app/domains/shared/design-system";
import styled from "styled-components";

export const OrderPaymentMethod = styled(Flex)``;

export const PaymentMethodContainer = styled(Flex)`
  justify-content: space-between;

  color: ${({ theme }) => theme.colors.grayDarkest};

  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-family: ${({ theme }) => theme.fonts.primary};
`;
