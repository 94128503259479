import {
  DrawerWithPush,
  DrawerWithPushProps,
} from "@app/domains/shared/design-system";

export const Mobile: React.FC<DrawerWithPushProps> = ({
  children,
  ...props
}) => {
  return (
    <DrawerWithPush {...props} title="">
      {children}
    </DrawerWithPush>
  );
};
