import { useCallback, useEffect, useState } from "react";
import { usePaymentMethods } from "@app/domains/checkout/hooks";
import { snackbar } from "@ifood/pomodoro-components";
import { useTheme } from "styled-components";
import { useAuthentication } from "@app/domains/authentication/context";
import { getDeviceId, getIsDevelopment } from "@app/domains/shared/config";
import { getPaymentSecretCookie } from "@app/domains/authentication/utils";
import {
  CardTokenPayload,
  Customer as CheckoutCustomer,
} from "@app/domains/checkout/models";
import { CookiesStorage } from "@app/domains/shared/models";
import { checkoutAboyeur } from "@app/domains/checkout/events";

export function usePaymentBridge(paymentMethod: string) {
  const { paymentMethods } = usePaymentMethods();
  const { customer } = useAuthentication();
  const { colors } = useTheme();
  const [cardToken, setCardToken] = useState<CardTokenPayload>();

  const iframeUrl = getIsDevelopment()
    ? "http://localhost:5174"
    : "https://security-card.ifood.com.br";

  useEffect(() => {
    window.addEventListener("message", receiveEvent);
    return () => {
      window.removeEventListener("message", receiveEvent);
    };
  }, []);

  const receiveEvent = useCallback((e) => {
    if (e.origin === iframeUrl) {
      const event = e.data as PaymentBridgetEvent;
      const { type } = event;

      const eventHandlers = {
        ON_HANDSHAKE: onHandShake,
        ON_BEFORE_TOKENIZE: onBeforeTokenize,
        ON_TOKENIZE_ERROR: onTokenizeError,
        ON_SUCCESS: onSuccess,
        ON_ERROR: onError,
      };

      const handler = eventHandlers[type];
      if (handler) handler(event);
      else console.warn("Unknown event type:", type);
    }
  }, []);

  const onHandShake = () => {
    sendSetupData();
  };

  const onBeforeTokenize = () => {
    checkoutAboyeur.events.payment.attemptCardRegistration();
    snackbar({
      message: "Salvando dados do cartão...",
      variant: "neutral",
    });
  };

  const onTokenizeError = (event: PaymentBridgetEvent) => {
    checkoutAboyeur.events.payment.failedCardRegistration(event);
    snackbar({ message: "Falha ao gerar token do cartão", variant: "error" });
  };

  const onSuccess = async (event: PaymentBridgetEvent) => {
    const { card } = event.data;

    const cardTokenPayload = {
      "buyer-reference-id": customer.user.id,
      card,
    } as CardTokenPayload;

    try {
      await CheckoutCustomer.activateCardToken(cardTokenPayload);
      setCardToken(cardTokenPayload);
      checkoutAboyeur.events.payment.successfullyCardRegistration();
      snackbar({
        message: "Cartão salvo com sucesso!",
        variant: "success",
      });
    } catch (error) {
      checkoutAboyeur.events.payment.failedCardRegistration(error);
      snackbar({
        message: "Falha ao salvar cartão",
        variant: "error",
      });
    }
  };

  const onError = (event: PaymentBridgetEvent) => {
    checkoutAboyeur.events.payment.failedCardRegistration(event);
    snackbar({
      message: "Falha na ponte de pagamento",
      variant: "error",
    });
  };

  const sendSetupData = () => {
    const iframe = document.getElementById(
      "payment-bridge",
    ) as HTMLIFrameElement;
    if (!iframe) return;

    iframe.contentWindow.postMessage(
      {
        paymentMethods: paymentMethods.list,
        selectedPaymentMethod: paymentMethod,
        accountId: customer.user.id,
        deviceId: getDeviceId(),
        devicePaymentSecret: getPaymentSecretCookie(),
        isTest: Boolean(
          getIsDevelopment() || CookiesStorage.get("x-ifood-test-mode"),
        ),
        customStyle: {
          form_box: `border-radius: 8px; padding: clamp(16px, 4vw, 24px) !important; > p { display: none; } > div { gap: 32px; } svg { width: auto !important; }`,
          input: `border-radius: 8px; background-color: white !important;`,
          input_label: `font-size: 16px; max-width: 100% !important; width: -webkit-fill-available;`,
          input_error_message: `font-size: 14px; display: none;`,
          submit_button: `background-color: ${colors.primary}; border: solid 1px ${colors.primary}; border-radius: 6px; font-size: 16px; font-weight: 500; width: 150px; `,
        },
      },
      iframeUrl,
    );
  };

  return {
    iframeUrl,
    cardToken,
  };
}

type PaymentBridgetEventType =
  | "ON_HANDSHAKE"
  | "ON_BEFORE_TOKENIZE"
  | "ON_TOKENIZE_ERROR"
  | "ON_SUCCESS"
  | "ON_ERROR";

type PaymentBridgetEvent = {
  type: PaymentBridgetEventType;
  data: PaymentBridgetEventData;
};

type PaymentBridgetEventData = {
  ok?: boolean;
  cause?: unknown;
  "data-error"?: string;
  card?: string;
  last4digits?: string;
  paymentMethodId?: string;
  cvv?: string | null;
};
