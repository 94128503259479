import { Text as PomodoroText } from "@app/domains/shared/design-system";
import styled from "styled-components";

export const Text = styled(PomodoroText)`
  color: ${({ theme }) => theme.colors.grayDarkest};
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;

export const Wrapper = styled.div`
  background-color: #f7f7f7;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  padding-right: 12px;

  > div {
    padding: 0;
    background-color: transparent;

    svg {
      width: 20px;
      color: ${({ theme }) => theme.colors.grayDarker};
    }
  }

  span {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.black};
  }
`;
