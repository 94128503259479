import { BannerList } from "../BannerList/BannerList";
import * as S from "./styles";
import { useMerchant } from "@app/domains/merchant/context";

export const BigBanners: React.VFC = () => {
  const { merchant } = useMerchant();
  const banners = merchant.getBanners();
  if (banners.length == 0) return null;
  return (
    <S.Container>
      <BannerList items={banners} size="BIG" />
    </S.Container>
  );
};
