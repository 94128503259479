import { Heading } from "@app/domains/shared/design-system";
import NextImage from "next/image";

import * as S from "./styles";

export const Title = () => (
  <S.TitleWrapper>
    <NextImage
      src="/images/order/good-rating.svg"
      width="155px"
      height="125px"
    />
    <Heading variant="small">Queremos saber a sua opinião!</Heading>
  </S.TitleWrapper>
);
