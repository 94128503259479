import React from "react";
import { DeliveryMethod } from "../DeliveryMethod";
import { ImageWithFallback } from "@app/domains/shared/design-system";
import { useMerchant } from "@app/domains/merchant/context";
import { DeliveryInfo } from "@app/domains/merchant/views";
import { SearchBar } from "@app/domains/catalog/views";
import { Flex } from "@ifood/pomodoro-components";
import { MinimumOrder } from "../MinimumOrder";
import * as S from "./styles";

export const MerchantShowcase: React.VFC = () => {
  const { merchant } = useMerchant();

  return (
    <S.Wrapper>
      <S.Content>
        <S.Info>
          <S.Logo>
            <ImageWithFallback
              src={merchant.getLogoUrl()}
              fallbackSrc="/images/no_image.png"
              layout="fill"
              objectFit="contain"
              alt={merchant.name}
            />
          </S.Logo>
          <Flex gap="4px" flexDirection="column" justifyContent="space-between">
            <S.Heading fontWeight="500" variant="regular" m={0}>
              {merchant.name}
            </S.Heading>
            <Flex gap="4px" alignItems="center">
              <MinimumOrder />
            </Flex>
          </Flex>
        </S.Info>

        <Flex gap="8px">
          <DeliveryMethod />
          <DeliveryInfo variant="small" />
        </Flex>

        <S.SearchContainer>
          <SearchBar />
        </S.SearchContainer>
      </S.Content>
    </S.Wrapper>
  );
};
