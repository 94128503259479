import { Button, Flex, Grid } from "@app/domains/shared/design-system";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
  flex-direction: column;
  text-align: left;
  gap: ${({ theme }) => theme.space.regular};
`;

export const List = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.space.regular};

  & button {
    &:hover {
      transition: all 100ms;
      transform: scale(1.05);
    }
  }
`;

export const BackButton = styled(Button)`
  margin-top: auto;
  font-size: 16px;
  font-weight: 500;
`;
