import {
  Flex,
  Button as PomodoroButton,
  Icon as PomodoroIcon,
  Text,
  TextLink,
} from "@app/domains/shared/design-system";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
  width: -webkit-fill-available;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.attention};
  box-shadow: ${({ theme }) => theme.shadows.elevate};
  border-radius: 12px;
`;

export const Container = styled(Flex)`
  flex-direction: column;
  width: 100%;
  padding: ${({ theme }) => theme.space.large};

  font-size: ${({ theme }) => theme.fontSizes.xs};
`;

export const Title = styled(Text)`
  margin-left: ${({ theme }) => theme.space.smaller};

  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;

export const Button = styled(PomodoroButton)`
  margin-top: ${({ theme }) => theme.space.regular};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Icon = styled(PomodoroIcon)`
  color: ${({ theme }) => theme.colors.white};
`;

export const Header = styled(Flex)`
  justify-content: flex-start;
`;

export const Cancelled = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PixFAQButton = styled(TextLink)`
  margin-top: ${(props) => props.theme.space.large};
  margin-bottom: ${(props) => props.theme.space.regular};

  text-align: center;
`;
