import { CustomerAddress } from "@app/domains/address/models";
import { checkoutAboyeur } from "@app/domains/checkout/events";
import { useMerchant } from "@app/domains/merchant/context";
import { Z_INDEXES } from "@app/domains/shared/constants";
import { DrawerWithPush, Text } from "@app/domains/shared/design-system";
import NextImage from "next/image";
import { useEffect, useMemo } from "react";
import SVG from "react-inlinesvg";

import { getBrandImageURL } from "@app/domains/shared/image-utils";
import { useCheckout } from "@app/domains/checkout/context";

import * as S from "./styles";
import { dateStringToDayName } from "@app/domains/shared/date-utils";
import { useIsMobile } from "@app/domains/shared/hooks";

let currentDate = new Date();

function getWeekday(date: Date) {
  const weekday = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];

  return weekday[date.getDay()];
}

type ConfirmOrderProps = {
  onConfirm: () => void;
  onClose: () => void;
};

export const ConfirmOrder: React.VFC<ConfirmOrderProps> = ({
  onConfirm,
  onClose,
}) => {
  const { merchant } = useMerchant();
  const { order } = useCheckout();

  const selectedTimeSlot = order.deliveryMethod.schedule.selectedTimeSlot;
  if (selectedTimeSlot) currentDate = new Date(selectedTimeSlot.endDateTime);

  useEffect(() => {
    checkoutAboyeur.events.confirmOrder.open();
    return function unmount() {
      checkoutAboyeur.events.confirmOrder.close();
    };
  }, []);

  const deliveryExpectedDate = useMemo(() => {
    return new Date(currentDate.getTime() + order.deliveryTime * 60 * 1000);
  }, [order.deliveryTime]);

  const deliveryExpectedDateDayLabel = useMemo(() => {
    if (selectedTimeSlot) {
      return dateStringToDayName(selectedTimeSlot.date).toLowerCase();
    } else {
      if (currentDate.getDate() !== deliveryExpectedDate.getDate())
        return "amanhã";

      return "hoje";
    }
  }, [deliveryExpectedDate]);

  const address = useMemo(() => {
    return order.isDefaultDeliveryMethod()
      ? order.address
      : CustomerAddress.fromMerchant(merchant);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchant.address, order.address]);

  const paymentBrandImageURL = useMemo(() => {
    return getBrandImageURL(order.paymentMethod);
  }, [order.paymentMethod]);

  function handleConfirmOrder() {
    onConfirm();
    onClose();
    checkoutAboyeur.events.confirmOrder.confirm();
  }

  function handleChangeInfo() {
    onClose();
    checkoutAboyeur.events.confirmOrder.changeInfo();
  }

  const isMobile = useIsMobile();
  const CustomDrawer = isMobile ? DrawerWithPush : S.CustomDialog;

  return (
    <CustomDrawer
      open={true}
      title={<S.Title>Confirme o pedido</S.Title>}
      onClose={onClose}
      zIndex={Z_INDEXES.CONFIRM_ORDER_DRAWER}
    >
      <S.Wrapper>
        <S.Item>
          <S.ItemIcon>
            <Text fontSize={12} lineHeight="normal">
              {getWeekday(deliveryExpectedDate)}
            </Text>
            <Text fontSize={16} fontWeight="bold" lineHeight="normal">
              {deliveryExpectedDate.getDate()}
            </Text>
          </S.ItemIcon>
          <S.ItemInfo>
            <S.ItemTitle>
              {order.isDefaultDeliveryMethod() &&
                `Entrega ${deliveryExpectedDateDayLabel}`}
              {order.isTakeoutDeliveryMethod() &&
                `Retirar ${deliveryExpectedDateDayLabel}`}
            </S.ItemTitle>
            <S.ItemDescription>
              {deliveryExpectedDateDayLabel},{" "}
              {selectedTimeSlot
                ? `${selectedTimeSlot.startTime} - ${selectedTimeSlot.endTime}`
                : `${order.deliveryTime} min`}
            </S.ItemDescription>
          </S.ItemInfo>
        </S.Item>
        <S.Divider />
        <S.Container>
          <Text fontSize="xs" lineHeight="normal">
            {order.isDefaultDeliveryMethod() && "Entregar em:"}
            {order.isTakeoutDeliveryMethod() && "Retirar em:"}
          </Text>
          <S.Item>
            <S.ItemIcon>
              <SVG src="/images/checkout/map.svg" />
            </S.ItemIcon>
            <S.ItemInfo>
              <S.ItemTitle>
                {address.streetName}
                {address.streetNumber ? `, ${address.streetNumber}` : ""} -{" "}
                {`${address.city}/${address.state}`}
              </S.ItemTitle>
              <S.ItemDescription>
                {address.neighborhood}{" "}
                {address.complement ? `• ${address.complement}` : ""}
              </S.ItemDescription>
            </S.ItemInfo>
          </S.Item>
        </S.Container>
        <S.Divider />
        <S.Item>
          <NextImage height={28} width={48} src={paymentBrandImageURL} alt="" />
          <S.ItemInfo>
            <S.ItemTitle>
              {order.paymentMethod.isOffline() &&
                order.isDefaultDeliveryMethod() &&
                "Pagamento na entrega"}
              {order.paymentMethod.isOffline() &&
                order.isTakeoutDeliveryMethod() &&
                "Pagamento na retirada"}
              {order.paymentMethod.isOnline() && "Pagamento pelo site"}
            </S.ItemTitle>
            <S.ItemDescription>
              <S.Text>{order.paymentMethod.getLabel()}</S.Text>
              <S.Text>{order.totalOrder.format()}</S.Text>
            </S.ItemDescription>
          </S.ItemInfo>
        </S.Item>
        <S.Button onClick={handleConfirmOrder}>
          Confirmar e fazer pedido
        </S.Button>
        <S.TextLink onClick={handleChangeInfo}>Alterar informações</S.TextLink>
      </S.Wrapper>
    </CustomDrawer>
  );
};
