import * as S from "./styles";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useMemo, useState } from "react";

import { OrderProvider } from "@app/domains/order/context";
import { CatalogProvider } from "@app/domains/catalog/context";
import { AccountProvider } from "@app/domains/account/context";
import { AddressProvider } from "@app/domains/address/context";
import { MerchantProvider } from "@app/domains/merchant/context";
import { CheckoutProvider } from "@app/domains/checkout/context";
import { AuthenticationProvider } from "@app/domains/authentication/context";
import { OnHistoryBackProvider } from "@app/domains/shared/navigation-utils";

import { BagBar } from "@app/domains/checkout/views";
import { OnGoingOrderAlert } from "@app/domains/order/views";
import { Header, Footer, Menu } from "@app/domains/merchant/layout/views";

import { CatalogResponse } from "@app/domains/catalog/models";
import { MerchantResponse } from "@app/domains/merchant/models";

import { CatalogFeatureToggles } from "@app/domains/catalog/utils/ssr";
import { useSanitizeLocalStorage } from "@app/domains/checkout/hooks";
import { Page, PageVariant } from "@app/domains/shared/page-wrapper";
import { useIsMobile } from "@app/domains/shared/hooks";
import { Loading } from "@ifood/pomodoro-components";
import { EmptyProps } from "@app/domains/shared/types";

const Profile = dynamic<EmptyProps>(
  () => import("@app/domains/account/views").then(({ Profile }) => Profile),
  { ssr: false },
);

const DeliveryAddress = dynamic<EmptyProps>(
  () =>
    import("@app/domains/address/views").then(
      ({ DeliveryAddress }) => DeliveryAddress,
    ),
  { ssr: false },
);

const IfoodAuthentication = dynamic<EmptyProps>(
  () =>
    import("@app/domains/authentication/views").then(
      ({ IfoodAuthentication }) => IfoodAuthentication,
    ),
  { ssr: false },
);

export type MerchantLayoutProps = {
  children: ReactNode;
  variant?: PageVariant;
  pageName: string;
  pageTitle: string;
  pageImage?: string;
  pageDescription?: string;
  merchantPath: string;
  merchantResponse: MerchantResponse;
  catalogResponse: CatalogResponse;
  featureToggles: CatalogFeatureToggles;
  isCanaryModeEnabled?: boolean;
  backgroundColor?: string;
};

export const MerchantLayout: React.VFC<MerchantLayoutProps> = ({
  children,
  variant,
  pageName,
  pageTitle,
  pageImage,
  pageDescription,
  merchantPath,
  merchantResponse,
  catalogResponse,
  featureToggles,
  isCanaryModeEnabled = false,
}) => {
  const {
    onlinePaymentsEnabled = {
      pix: false,
      cards: false,
    },
  } = featureToggles;

  const isMobile = useIsMobile();
  const router = useRouter();
  useSanitizeLocalStorage();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = () => setLoading(true);
    const handleComplete = () => setLoading(false);

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, []);

  const merchantDescription = useMemo(
    () =>
      merchantResponse
        ? `Faça seu pedido em ${merchantResponse.name}, delivery de ${
            merchantResponse.mainCategory.name
          } em ${merchantResponse.address.district || ""}, ${
            merchantResponse.address.city
          }, ${merchantResponse.address.state} `
        : "",
    [merchantResponse],
  );

  if (!merchantResponse) return null;

  return (
    <Page
      name={pageName}
      title={pageTitle}
      variant={variant}
      imageUrl={pageImage}
      merchantResponse={merchantResponse}
      merchantPath={merchantPath}
      description={pageDescription ?? merchantDescription}
      isCanaryModeEnabled={isCanaryModeEnabled}
    >
      <OnHistoryBackProvider>
        <MerchantProvider merchantResponse={merchantResponse}>
          <AuthenticationProvider>
            <AccountProvider>
              <AddressProvider merchantResponse={merchantResponse}>
                <CatalogProvider catalogResponse={catalogResponse}>
                  <OrderProvider>
                    <CheckoutProvider
                      onlinePaymentsEnabled={onlinePaymentsEnabled}
                    >
                      <S.FixedHeader>
                        <OnGoingOrderAlert />
                        {!isMobile && <Header />}
                      </S.FixedHeader>

                      <S.ContentWrapper>
                        <S.Content>{children}</S.Content>
                      </S.ContentWrapper>

                      <Footer></Footer>

                      <S.BottomFloatingStack>
                        <BagBar />
                        <Menu />
                      </S.BottomFloatingStack>

                      {loading && (
                        <S.LoadingWrapper>
                          <Loading color="primary" variant="large" />
                        </S.LoadingWrapper>
                      )}

                      <DeliveryAddress />
                      <IfoodAuthentication />
                      <Profile />
                    </CheckoutProvider>
                  </OrderProvider>
                </CatalogProvider>
              </AddressProvider>
            </AccountProvider>
          </AuthenticationProvider>
        </MerchantProvider>
      </OnHistoryBackProvider>
    </Page>
  );
};
