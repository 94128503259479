import { useCatalog } from "@app/domains/catalog/context";
import React, { useMemo } from "react";

import { useRouter } from "next/router";
import { ItemGridLayout } from "@app/domains/catalog/layout";
import { PageNavigation } from "./PageNavigation/PageNavigation";

export const CategoryPage: React.VFC = () => {
  const { catalog } = useCatalog();
  const router = useRouter();

  const categories = useMemo(() => catalog.getVisibleCategories(), [catalog]);
  const category = useMemo(
    () =>
      categories.find((category) => category.code == router.query.categoryCode),
    [categories],
  );
  const categoryItems = useMemo(() => {
    return category.items.map((item, index) => {
      item.order = index;
      return item;
    });
  }, [category]);

  return (
    <>
      <ItemGridLayout
        title={category.name}
        catalogItems={categoryItems}
      ></ItemGridLayout>
      <PageNavigation />
    </>
  );
};
