import { Flex } from "@app/domains/shared/design-system";
import styled from "styled-components";

import { DialogTitle } from "../../DialogTitle";
import { errorPaymentMethodsMinHeight } from "../ErrorPaymentMethods/styles";

export const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  min-height: ${errorPaymentMethodsMinHeight};
`;

export const Title = styled(DialogTitle)`
  visibility: hidden;
`;
