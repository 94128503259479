import { ChevronDown, ChevronRight, ChevronUp } from "@ifood/pomodoro-icons";
import { useMerchant } from "@app/domains/merchant/context";
import NextImage from "next/image";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import SVG from "react-inlinesvg";

import { Order } from "../../models";
import { OrderInfo } from "../OrderInfo";
import { ProgressBar } from "../ProgressBar";
import * as S from "./styles";
import { orderAboyeur } from "../../events";
import { dateStringToDayName } from "@app/domains/shared/date-utils";
import { formatDateToDayHourMinute } from "@app/utils/date";

type SummaryProps = {
  order: Order;
};

export const InProgressSummary: React.VFC<SummaryProps> = ({ order }) => {
  const [open, setOpen] = useState(false);
  const { merchant } = useMerchant();
  const router = useRouter();

  const inProgressOrConcludedStatusesInfo =
    order.getInProgressOrConcludedStatusesInfo();
  const statuses = order.isPlaced()
    ? inProgressOrConcludedStatusesInfo
    : inProgressOrConcludedStatusesInfo.slice(1);
  const handshakeCode = order.getHandshakeMetadata()?.handshakeCode;

  const redirectToTracking = () => {
    router.push(`/${merchant.query}/order/${order.id}/tracking`);
  };

  useEffect(() => {
    orderAboyeur.events.orderStatus.viewWaiting();
  }, []);

  return (
    <S.Wrapper>
      <S.Container>
        <S.Header>
          {order.isTakeout ? (
            <S.Title>Previsão de retirada </S.Title>
          ) : (
            <S.Title>Previsão de entrega </S.Title>
          )}

          <S.Time>
            {dateStringToDayName(order.expectedDeliveryDay)},{" "}
            <b>
              {order.expectedDelivery} - {order.extraExpectDelivery}
            </b>
          </S.Time>
        </S.Header>
        <S.Description>Atualizado em tempo real</S.Description>
        <S.ProgressBarContainer>
          <ProgressBar orderStatus={order.lastStatus} />
        </S.ProgressBarContainer>
        <S.CurrentStatus onClick={() => setOpen(!open)}>
          <NextImage height={10} width={10} src={"/icons/radar.svg"} alt="" />
          <S.CurrentStatusText>
            {order.isPlaced() ? (
              <S.Title>Aguardando confirmação</S.Title>
            ) : (
              <>
                <S.Title>{inProgressOrConcludedStatusesInfo[0]?.label}</S.Title>
                {handshakeCode && (
                  <S.Title>
                    Use o código {handshakeCode} para receber o pedido.
                  </S.Title>
                )}
              </>
            )}
          </S.CurrentStatusText>

          <S.ToggleButton>
            {!open && <ChevronDown />} {open && <ChevronUp />}
          </S.ToggleButton>
        </S.CurrentStatus>
        {open && (
          <S.PreviousStatusList>
            {statuses.map((statusInfo) => (
              <S.Status key={`${statusInfo.label}-${statusInfo.updatedAt}`}>
                <S.Line />
                <S.StatusContainer>
                  <S.StatusCircle />
                  <S.Label>{statusInfo.label}</S.Label>
                  <S.UpdatedAt>
                    {formatDateToDayHourMinute(new Date(statusInfo.updatedAt))}
                  </S.UpdatedAt>
                </S.StatusContainer>
              </S.Status>
            ))}
          </S.PreviousStatusList>
        )}
      </S.Container>

      <OrderInfo order={order} />
    </S.Wrapper>
  );
};
