import { Flex, Text as PomodoroText } from "@app/domains/shared/design-system";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
  width: -webkit-fill-available;

  box-shadow: 0px -0.5px 0px #dcdcdc;
  background-color: ${({ theme }) => theme.colors.white};

  justify-content: space-between;
  padding: 11px 32px 11px 32px;
`;

type ContainerProps = {
  isActive?: boolean;
};

export const Container = styled(Flex)<ContainerProps>`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 2px;

  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.primary : theme.colors.grayDarker} !important;

  & span {
    color: ${({ isActive, theme }) =>
      isActive ? theme.colors.primary : theme.colors.grayDarker} !important;
  }

  cursor: pointer;
`;

export const Text = styled(PomodoroText)`
  font-size: 10px;
  line-height: 10px;
`;

export const OnGoingOrder = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: solid 3px ${({ theme }) => theme.colors.primary};
  box-shadow: 0px 0px 0px 2px white;
  width: 4px;
  aspect-ratio: 1;
  border-radius: 50%;
  position: absolute;
  top: -3px;
  right: 5px;
`;
