import { useEffect } from "react";

export const useSanitizeLocalStorage = () => {
  useEffect(() => {
    Object.entries(localStorage).forEach(([key, value]) => {
      const isUndefined = !value || value === "undefined";
      if (isUndefined) localStorage.removeItem(key);
    });
  }, []);
};
