import { useAddress } from "@app/domains/address/context";
import { useAuthentication } from "@app/domains/authentication/context";
import { Item as CatalogItem } from "@app/domains/catalog/models";
import { checkoutAboyeur } from "@app/domains/checkout/events";
import {
  CardTokenResponse,
  Item,
  ItemUnit,
  Order,
  PaymentMethod,
} from "@app/domains/checkout/models";
import { OnlinePaymentsEnabled } from "@app/domains/checkout/types/types";
import { usePersistedItems } from "@app/domains/checkout/hooks";
import { useMerchant } from "@app/domains/merchant/context";
import { DeliveryMethod } from "@app/domains/merchant/models";
import { snackbar } from "@app/domains/shared/design-system";
import { ecommerceEvents } from "@app/domains/shared/ecommerce-events";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { EmptyProps } from "@app/domains/shared/types";

const CheckoutDetails = dynamic<EmptyProps>(
  () => import("../views").then(({ CheckoutDetails }) => CheckoutDetails),
  { ssr: false },
);

const NoAddress = dynamic<EmptyProps>(
  () => import("../views").then(({ NoAddress }) => NoAddress),
  { ssr: false },
);

type CheckoutDetailsStatus = "IDLE" | "OPEN";
type SetOrder = Order | ((prevOrder: Order) => Order);

export type CheckoutContextValue = {
  order: Order;
  updateOrder: (setOrder: SetOrder) => void;
  addItem: (
    catalogItem: CatalogItem,
    quantity: number,
    unit: ItemUnit,
    observation: string,
  ) => Promise<boolean>;
  removeItem: (catalogItem: CatalogItem) => boolean;
  removeAllItems: () => void;
  getItemInOrder: (itemId: string) => Item;
  setDocument: (document?: string) => void;
  setCardToken: (cardToken: CardTokenResponse, payment: PaymentMethod) => void;
  setPaymentMethod: (paymentMethod: PaymentMethod) => void;
  confirmPriceChanges: () => void;
  onlinePaymentsEnabled: OnlinePaymentsEnabled;
  updateOrderOnItemNotFound: (items: string[]) => void;
  updateOrderOnItemChangePrice: (catalogItems: CatalogItem[]) => void;
  checkoutDetailsStatus: CheckoutDetailsStatus;
  setCheckoutDetailsStatus: (status: CheckoutDetailsStatus) => void;
};

export const CheckoutContext = createContext<CheckoutContextValue>(undefined);

export const CheckoutProvider: React.FC<{
  children: ReactNode;
  onlinePaymentsEnabled?: OnlinePaymentsEnabled;
}> = ({
  children,
  onlinePaymentsEnabled = {
    pix: false,
    cards: false,
  } as OnlinePaymentsEnabled,
}) => {
  const router = useRouter();
  const { merchant } = useMerchant();
  const { customer } = useAuthentication();
  const [items, setItems] = usePersistedItems();
  const { deliveryMethod, setDeliveryMethod, getAddress } = useAddress();
  const address = useMemo(() => getAddress("MERCHANT"), [getAddress]);

  const order = new Order(merchant, items, address, deliveryMethod, customer);
  const [checkout, setCheckout] = useState<CheckoutContextValue>(() => {
    return {
      order,
      updateOrder,
      addItem,
      removeItem,
      removeAllItems,
      getItemInOrder,
      setDocument,
      setCardToken,
      setPaymentMethod,
      confirmPriceChanges,
      onlinePaymentsEnabled,
      updateOrderOnItemNotFound,
      updateOrderOnItemChangePrice,
      checkoutDetailsStatus: "IDLE",
      setCheckoutDetailsStatus,
    };
  });

  const [isNoAddressOpen, setIsNoAddressOpen] = useState(false);
  const isCheckoutDetailsOpen = checkout.checkoutDetailsStatus == "OPEN";
  const onClose = useCallback(() => setCheckoutDetailsStatus("IDLE"), []);

  useEffect(() => {
    if (isCheckoutDetailsOpen) onClose();
  }, [router.query]);

  useEffect(() => {
    const { checkoutDetailsStatus } = checkout;
    localStorage.setItem("checkoutStatus", checkoutDetailsStatus);
  }, [checkout.checkoutDetailsStatus]);

  useEffect(() => {
    if (!deliveryMethod) return;

    async function onUpdateDeliveryMethod() {
      const newOrder = checkout.order.withDeliveryMethod(deliveryMethod);
      updateOrder(newOrder);
    }

    onUpdateDeliveryMethod();
  }, [deliveryMethod]);

  useEffect(() => {
    if (!address) return;

    async function onUpdateAddressAndDeliveryMethod() {
      const newOrder = checkout.order.withAddress(address);
      if (!checkout.order.customer) return updateOrder(newOrder);
      await newOrder.updateOrCreateCustomerAddress();
      updateOrder(newOrder);
    }

    onUpdateAddressAndDeliveryMethod();
  }, [address]);

  useEffect(() => {
    if (!customer) return;

    async function onUpdateCustomerOrAddress() {
      const newOrder = await checkout.order
        .withCustomer(customer)
        .updateOrCreateCustomerAddress();
      updateOrder(newOrder);
    }

    onUpdateCustomerOrAddress();
  }, [customer]);

  useEffect(() => {
    if (!address || deliveryMethod) return;

    async function onInsertAutomaticallyDeliveryMethod() {
      const deliveryMethodResponse = await merchant.getDeliveryMethod(
        address.coordinates.latitude,
        address.coordinates.longitude,
      );

      if (!deliveryMethodResponse) return;

      const deliveryMethod = DeliveryMethod.fromApi(deliveryMethodResponse);
      setDeliveryMethod(deliveryMethod);

      const newOrder = checkout.order
        .withAddress(address)
        .withDeliveryMethod(deliveryMethod);

      updateOrder(newOrder);
    }

    onInsertAutomaticallyDeliveryMethod();
  }, [merchant]);

  function updateOrder(setOrder: SetOrder) {
    setCheckout(({ order: prevOrder, ...prevCheckout }) => {
      const newOrder =
        typeof setOrder === "function" ? setOrder(prevOrder) : setOrder;

      setItems(newOrder.itemsList);

      return {
        ...prevCheckout,
        order: newOrder,
      };
    });
  }

  async function addItem(
    catalogItem: CatalogItem,
    quantity: number,
    unit: ItemUnit,
    observation: string,
  ) {
    if (
      merchant.hasDeliveryFeature() &&
      !merchant.hasTakeoutFeature() &&
      !checkout.order.deliveryMethod
    ) {
      checkoutAboyeur.events.details.noAddress();
      setIsNoAddressOpen(true);
      return false;
    }

    if (merchant.hasTakeoutFeature() && !checkout.order.deliveryMethod) {
      checkoutAboyeur.events.details.noDeliveryMethod();
      setTakeoutDeliveryMethod();
    }

    updateOrder((prevOrder) =>
      prevOrder.withItem(
        Item.fromCatalogItem(catalogItem, quantity, unit, observation),
      ),
    );

    ecommerceEvents.addToCart(catalogItem);
    checkoutAboyeur.events.details.addItem(
      catalogItem.description,
      catalogItem.id,
    );

    return true;
  }

  function removeItem(catalogItem: CatalogItem) {
    const item = Item.fromCatalogItem(catalogItem, 0);

    checkoutAboyeur.events.details.removeItem(
      catalogItem.description,
      catalogItem.id,
    );
    ecommerceEvents.removeFromCart(item.catalogItem);

    updateOrder((prevOrder) => {
      const newOrder = prevOrder.withoutItem(item);
      if (!newOrder.hasItems()) onClose();
      return newOrder;
    });

    return true;
  }

  function removeAllItems() {
    updateOrder((prevOrder) => {
      const newOrder = prevOrder.removeAllItems();

      onClose();

      snackbar({
        variant: "success",
        message: "Sua sacola está vazia",
      });

      checkoutAboyeur.events.details.clear();

      return newOrder;
    });
  }

  function getItemInOrder(itemId: string) {
    return order.itemsList.find((item) => item.catalogItem.id == itemId);
  }

  function setDocument(document?: string) {
    updateOrder((prevOrder) => prevOrder.withDocument(document));
    snackbar({
      variant: "success",
      message: "CPF/CNPJ aplicado",
    });
  }

  function setCardToken(cardToken: CardTokenResponse, payment: PaymentMethod) {
    checkoutAboyeur.events.payment.selectMethod(
      payment.name,
      payment.type.name,
    );

    updateOrder((prevOrder) =>
      prevOrder.withCardToken(cardToken).withPaymentMethod(payment),
    );
  }

  function setPaymentMethod(paymentMethod?: PaymentMethod) {
    if (!paymentMethod) {
      updateOrder((prevOrder) => prevOrder.withPaymentMethod());
      return;
    }

    checkoutAboyeur.events.payment.selectMethod(
      paymentMethod.name,
      paymentMethod.type.name,
    );
    ecommerceEvents.checkoutStep(1, paymentMethod.name);

    snackbar({
      variant: "success",
      message: "Forma de pagamento escolhida",
    });

    updateOrder((prevOrder) =>
      prevOrder.withCardToken().withPaymentMethod(paymentMethod),
    );
  }

  function updateOrderOnItemNotFound(items: string[]) {
    items.forEach((id) => {
      const itemInOrder = checkout.order.itemsList.find(
        (item) => item.catalogItem.code === id,
      );

      if (!itemInOrder) return;

      const { quantity, observation, unit, catalogItem } = itemInOrder;
      const item = Item.fromCatalogItem(
        catalogItem,
        quantity,
        unit,
        observation,
        "UNAVAILABLE",
      );

      updateOrder((prevOrder) => {
        const orderWithoutItem = prevOrder.withoutItem(itemInOrder);
        const updatedOrder = orderWithoutItem.withItem(item);
        return updatedOrder;
      });
    });
  }

  function updateOrderOnItemChangePrice(catalogItems: CatalogItem[]) {
    catalogItems.forEach((newCatalogItem) => {
      const itemInOrder = checkout.order.itemsList.find(
        (item) => item.catalogItem.code === newCatalogItem.code,
      );

      if (!itemInOrder) return;

      const { quantity, observation, unit, catalogItem } = itemInOrder;
      const newItemPrice = newCatalogItem.getBasePrice().getValue();
      const currentItemPrice = catalogItem.getBasePrice().getValue();
      const priceHasChanged = newItemPrice != currentItemPrice;

      if (priceHasChanged) {
        newCatalogItem.categoryCode = catalogItem.categoryCode;
        newCatalogItem.category = catalogItem.category;

        const item = Item.fromCatalogItem(
          newCatalogItem,
          quantity,
          unit,
          observation,
          "PRICE_CHANGED",
        );

        updateOrder((prevOrder) => {
          const orderWithoutItem = prevOrder.withoutItem(itemInOrder);
          const updatedOrder = orderWithoutItem.withItem(item);
          return updatedOrder;
        });
      }
    });
  }

  function confirmPriceChanges() {
    checkout.order.itemsList.forEach((itemInOrder) => {
      const { quantity, observation, unit, catalogItem, status } = itemInOrder;

      if (status == "PRICE_CHANGED") {
        const item = Item.fromCatalogItem(
          catalogItem,
          quantity,
          unit,
          observation,
          "AVAILABLE",
        );

        updateOrder((prevOrder) => {
          const orderWithoutItem = prevOrder.withoutItem(itemInOrder);
          const updatedOrder = orderWithoutItem.withItem(item);
          return updatedOrder;
        });
      }
    });
  }

  function setCheckoutDetailsStatus(status: CheckoutDetailsStatus) {
    setCheckout({ ...checkout, checkoutDetailsStatus: status });
  }

  async function setTakeoutDeliveryMethod() {
    try {
      const takeoutMethodResponse = await merchant.getTakeoutMethod();
      const takeoutMethod = DeliveryMethod.fromApi(takeoutMethodResponse);
      setDeliveryMethod(takeoutMethod);
    } catch (error: any) {
      checkoutAboyeur.events.catch.onError(error as Error);
    }
  }

  return (
    <CheckoutContext.Provider value={checkout}>
      {children}
      <CheckoutDetails open={isCheckoutDetailsOpen} onClose={onClose} />
      <NoAddress
        open={isNoAddressOpen}
        onClose={() => setIsNoAddressOpen(false)}
      />
    </CheckoutContext.Provider>
  );
};

CheckoutProvider.displayName = "CheckoutProvider";
