import { accountAboyeur } from "@app/domains/account/events";
import { useAddress } from "@app/domains/address/context";
import { useAuthentication } from "@app/domains/authentication/context";
import { useMerchant } from "@app/domains/merchant/context";
import {
  Exit,
  Order,
  PinOutlined,
  TrashOutlined,
} from "@app/domains/shared/design-system";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";

import * as S from "./styles";

export const SuspendedMenu: React.VFC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const router = useRouter();
  const menuRef = useRef(null);

  const { merchant } = useMerchant();
  const { customer, logout } = useAuthentication();
  const { openAddress } = useAddress();

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (!menuRef || menuRef.current.contains(event.target)) return;
      setTimeout(() => onClose(), 100);
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [onClose]);

  function handleRedirectToOrder() {
    accountAboyeur.events.headerSuspendedMenu.orders();
    router.push(`/${merchant.query}/order`);
    onClose();
  }

  function handleRedirectToDeleteAccount() {
    accountAboyeur.events.headerSuspendedMenu.delete();
    router.push(`/${merchant.query}/excluir-conta`);
    onClose();
  }

  function handleAddresses() {
    accountAboyeur.events.headerSuspendedMenu.addresses();
    openAddress("MERCHANT");
    onClose();
  }

  function handleLogout() {
    accountAboyeur.events.headerSuspendedMenu.logout();
    logout();
    onClose();
  }

  if (!customer) return null;

  return (
    <S.Wrapper ref={menuRef}>
      <S.Title>Olá, {customer.user.name}</S.Title>
      {customer && (
        <>
          <S.Option onClick={handleRedirectToOrder}>
            <S.Icon component={Order} size="s" />
            <S.TextLink>Meus Pedidos</S.TextLink>
          </S.Option>
          <S.Option onClick={handleAddresses}>
            <S.Icon component={PinOutlined} size="s" />
            <S.TextLink>Endereços</S.TextLink>
          </S.Option>
          <S.Option onClick={handleRedirectToDeleteAccount}>
            <S.Icon component={TrashOutlined} size="s" />
            <S.TextLink>Excluir conta</S.TextLink>
          </S.Option>
          <S.Option onClick={handleLogout}>
            <S.Icon component={Exit} size="s" />
            <S.TextLink>Sair</S.TextLink>
          </S.Option>
        </>
      )}
    </S.Wrapper>
  );
};
