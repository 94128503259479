import { Money } from "@app/domains/shared/models";
import { DeliveryMethod, TimeSlot } from "@app/domains/merchant/models";
import { Carousel, Text } from "@app/domains/shared/design-system";

import * as S from "./styles";
import { Flex } from "@ifood/pomodoro-components";
import { dateStringToDayName } from "@app/domains/shared/date-utils";
import { useCheckout } from "@app/domains/checkout/context";
import { DeliverySlots } from "@app/domains/checkout/views/DeliverySlots";
import { useState } from "react";
import { useUpdateDeliveryMethod } from "@app/domains/checkout/hooks";
import { checkoutAboyeur } from "@app/domains/checkout/events";
import { AlertFilled } from "@ifood/pomodoro-icons";

export type SlotOption = {
  date: string;
  price: string;
  dateName: string;
  dateNumber: number;
  timeSlots: TimeSlot[];
};

export const TimeSlotSelect: React.VFC = () => {
  const { order } = useCheckout();
  const { updateDeliveryMethod } = useUpdateDeliveryMethod();
  const [openDeliverySlots, setOpenDeliverySlots] = useState(false);

  const deliveryMethod: DeliveryMethod = order.deliveryMethod;
  if (!deliveryMethod) return null;

  const currentTimeSlot = deliveryMethod.schedule?.selectedTimeSlot;
  const timeSlots = deliveryMethod.schedule?.timeSlots;
  if (!timeSlots || timeSlots.length == 0) {
    return (
      <S.EmptySlots>
        <AlertFilled />
        <Text color="grayDarkest" fontWeight="bold" lineHeight="18px">
          No momento, não há nenhum horário para agendamento disponível
        </Text>
      </S.EmptySlots>
    );
  }

  const slots = groupTimeSlotsByDate(timeSlots);
  if (!slots) return null;

  const handleSlotClick = (selectedTimeSlot: TimeSlot) => {
    deliveryMethod.schedule.selectedTimeSlot = selectedTimeSlot;
    updateDeliveryMethod(deliveryMethod);
    checkoutAboyeur.events.deliveryMethod.clickSelectedDelivery(
      selectedTimeSlot.date,
      `${selectedTimeSlot.startTime} - ${selectedTimeSlot.endTime}`,
    );
  };

  const currentDate = currentTimeSlot?.date ?? timeSlots[0].date;
  const filteredTimeSlots =
    slots.find((slot) => slot.date == currentDate)?.timeSlots.slice(0, 3) ?? [];

  if (currentTimeSlot && !filteredTimeSlots.includes(currentTimeSlot)) {
    filteredTimeSlots.unshift(currentTimeSlot);
  } else if (!currentTimeSlot) handleSlotClick(filteredTimeSlots.at(0));

  const TimeSlotCarousel: React.VFC = () => (
    <Carousel simultaneousItems={3} gap="16px" mobileWidth="150px">
      {filteredTimeSlots.map((timeSlot) => {
        return (
          <S.SlotCard
            key={timeSlot.id}
            active={timeSlot.id == currentTimeSlot?.id}
            onClick={() => handleSlotClick(timeSlot)}
          >
            <Text
              fontWeight="500"
              fontSize="12px"
              lineHeight="16px"
              color="grayDarkest"
            >
              {dateStringToDayName(timeSlot.date)}, {timeSlot.startTime} -{" "}
              {timeSlot.endTime}
            </Text>
            <Text
              fontWeight="300"
              fontSize="10px"
              lineHeight="12px"
              color="grayDarkest"
            >
              Agendada
            </Text>
            <Flex gap="small" mt="regular">
              <Text
                fontWeight="500"
                fontSize="14px"
                lineHeight="16px"
                color={timeSlot.price == 0 ? "positive" : "grayDarkest"}
              >
                {timeSlot.price === 0
                  ? "Grátis"
                  : new Money(timeSlot.price / 100).format()}
              </Text>
            </Flex>
          </S.SlotCard>
        );
      })}
    </Carousel>
  );

  return (
    <S.Wrapper>
      <S.Title>
        Tipos de {order.isDefaultDeliveryMethod() ? "entrega" : "retirada"}
      </S.Title>
      <Text
        fontWeight="400"
        fontSize="12px"
        lineHeight="16px"
        color="grayDarkest"
        mb="regular"
      >
        Selecione uma data
      </Text>

      <S.CarouselWrapper>
        <TimeSlotCarousel />
      </S.CarouselWrapper>

      <S.ScheduleButton onClick={() => setOpenDeliverySlots(true)}>
        Agendar {order.isDefaultDeliveryMethod() ? "entrega" : "retirada"}
      </S.ScheduleButton>
      <DeliverySlots
        open={openDeliverySlots}
        onClose={() => setOpenDeliverySlots(false)}
      />
    </S.Wrapper>
  );
};

function groupTimeSlotsByDate(timeSlots: TimeSlot[]): SlotOption[] {
  return Object.values(
    timeSlots.reduce((acc, curr) => {
      const { date, price } = curr;
      if (!acc[date]) {
        acc[date] = {
          date: date,
          dateName: dateStringToDayName(date),
          dateNumber: new Date(date).getDate() + 1,
          price: price === 0 ? "Grátis" : new Money(price / 100).format(),
          timeSlots: [],
        };
      }
      acc[date].timeSlots.push(curr);
      return acc;
    }, {}),
  );
}
