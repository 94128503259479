import { Flex, Text } from "@ifood/pomodoro-components";
import { Button, Drawer } from "@app/domains/shared/design-system";
import styled, { DefaultTheme } from "styled-components";

type Themed = { theme: DefaultTheme };
const belowDesktop = ({ theme }: Themed) => `max-width: ${theme.screens.m.max}`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  padding-right: ${({ theme }) => theme.space.large};
  padding-left: ${({ theme }) => theme.space.large};

  @media (${belowDesktop}) {
    padding-right: ${({ theme }) => theme.space.regular};
    padding-left: ${({ theme }) => theme.space.regular};
  }
`;

export const CheckoutButton = styled(Button)<{ blocked?: boolean }>`
  width: 100%;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes.s};

  ${({ blocked }) =>
    blocked &&
    "color: #A6A6A6 !important; background-color: #DCDCDC !important;"}

  @media (${belowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.s};
  }
`;

export const Divider = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.colors.grayLight};
  width: 100%;
  height: 1px;
`;

export const DrawerFooter = styled(Drawer.Footer)`
  display: flex;
  flex-direction: column;

  bottom: 0;
  margin-top: auto;

  gap: ${({ theme }) => theme.space.regular};
  background-color: ${({ theme }) => theme.colors.white};
  position: sticky;

  --border-top: 1px solid ${({ theme }) => theme.colors.grayLight};
  border-top: var(--border-top);

  --padding: ${({ theme }) => theme.space.larger};
  @media (${belowDesktop}) {
    --padding: ${({ theme }) => theme.space.regular};
    gap: ${({ theme }) => theme.space.smaller};
    z-index: 2;
  }

  padding: 0;
  padding-bottom: var(--padding);
`;

export const ButtonClearCheckout = styled.button`
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-family: ${({ theme }) => theme.font.family.ifood.headings};
  border-radius: ${({ theme }) => theme.radii.m};
  font-weight: bold;
  height: 48px;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};

  &:hover {
    background-color: ${({ theme }) => theme.colors.grayLight};
  }

  @media (${belowDesktop}) {
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.fontSizes.xs};
    font-weight: 400;
    position: fixed;
    top: 0;
    right: ${({ theme }) => theme.space.regular};
    padding: ${({ theme }) => theme.space.regular} 0;
    height: auto;
  }
`;

export const EmptyBagBody = styled(Flex)`
  flex: auto;
  align-items: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.regular};
  padding: ${({ theme }) => theme.space.large};
`;

export const EmptyBagText = styled(Text)`
  margin-bottom: auto;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes.l};
  line-height: ${({ theme }) => theme.lineHeights.xl};
  @media (${belowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.m};
  }
`;
