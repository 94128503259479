import {
  createAuthenticatedAxiosClient,
  getPaymentSecretCookie,
} from "@app/domains/authentication/utils";
import {
  groceriesApiBffURL,
  getDeviceId,
  getIsDevelopment,
} from "@app/domains/shared/config";
import { CookiesStorage } from "@app/domains/shared/models";
import { AxiosInstance } from "axios";

import {
  CardTokenPayload,
  CardTokenResponse,
  CardTokensResponse,
  PaymentMethod,
} from ".";

export class Customer {
  static client: AxiosInstance;

  static initClient(): void {
    Customer.client = createAuthenticatedAxiosClient(groceriesApiBffURL);
  }

  static mountCardTokenHeaders() {
    const paymentSecret = getPaymentSecretCookie();
    const isTestMode = Boolean(
      getIsDevelopment() || CookiesStorage.get("x-ifood-test-mode"),
    );

    return {
      "X-Ifood-Device-Payment-Secret": paymentSecret,
      "X-Ifood-Device-Id": getDeviceId(),
      "x-test-mode": isTestMode,
    };
  }

  static async getCardTokens(): Promise<CardTokensResponse> {
    if (!Customer.client) {
      Customer.initClient();
    }
    const headers = Customer.mountCardTokenHeaders();

    const { data } = await Customer.client.get<CardTokensResponse>(
      "/payment/v1/customers/me/card-tokens",
      {
        headers,
      },
    );

    return data.filter((cardToken) =>
      PaymentMethod.canUseThisBrand(cardToken.brand.name),
    );
  }

  static async deleteCardToken(cardId: string): Promise<void> {
    if (!Customer.client) {
      Customer.initClient();
    }

    const headers = Customer.mountCardTokenHeaders();

    await Customer.client.delete(
      `/payment/v1/customers/me/card-tokens/${cardId}`,
      {
        headers,
      },
    );
  }

  static async activateCardToken(
    cardToken: CardTokenPayload,
  ): Promise<CardTokenResponse> {
    if (!Customer.client) {
      Customer.initClient();
    }

    const headers = Customer.mountCardTokenHeaders();

    const { data } = await Customer.client.post<CardTokenResponse>(
      "/payment/v1/customers/me/card-tokens/-/activation",
      cardToken,
      {
        headers,
      },
    );

    return data;
  }
}
