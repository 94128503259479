import React from "react";
import * as S from "./styles";
import NextImage from "next/image";
import { useRouter } from "next/router";
import { useCatalog } from "@app/domains/catalog/context";
import { SearchBar } from "@app/domains/catalog/views";
import { Category } from "@app/domains/catalog/models";
import { useMerchant } from "@app/domains/merchant/context";

export const SearchPage: React.VFC = () => {
  const { catalog } = useCatalog();
  const { merchant } = useMerchant();
  const router = useRouter();

  const categories = catalog
    .getVisibleCategories()
    .filter((category) => category.logoUrl);

  const categoryLogoUrl = (src: string) =>
    `https://static-images.ifood.com.br${src}`;

  const handleClick = (category: Category) => {
    router.push(`/${merchant.query}/categoria/${category.code}`);
  };

  const getBackgroundColor = (category: Category) => {
    const colorMap: { [key: string]: string } = {
      limpeza: "#c2fdf9",
      basicos: "#76b8a5",
      feira: "#fae495",
      carne: "#e5bcb7",
      alcool: "#fadeb9",
      bebidas: "#ebb7b5",
      infantis: "#ffe6d8",
      cuidados: "#eacdb0",
      higiene: "#e2c9c3",
      biscoitos: "#d5acbe",
      frios: "#fdf2e7",
      condimentos: "#dcdd9e",
      leite: "#f8d3d7",
      doces: "#f6c6d6",
      padaria: "#f9d597",
      matinais: "#eacdb0",
      congelados: "#edb6b4",
      pet: "#c3dafd",
      utensilios: "#ffe5dd",
      etnicos: "#ffd7c6",
      bazar: "#ffe0bc",
      papelaria: "#fdf2e7",
      suplementos: "#e9e3e3",
      brinquedos: "#fae495",
      eletro: "#c2fdf9",
      gastronomia: "#a2cdf8",
      sandalias: "#fce188",
      funcionais: "#ffe6a6",
      perfumaria: "#fad0c2",
      conveniencia: "#bbefff",
      remedios: "#ffdfbe",
      socorros: "#ffcccc",
    };
    for (const key in colorMap) {
      if (category.logoUrl.includes(key)) {
        return colorMap[key];
      }
    }
    return "#e6e6e6";
  };

  return (
    <S.Wrapper>
      <SearchBar />
      <S.CategoryGrid>
        {categories.map((category) => {
          return (
            <S.CategoryCard
              key={category.code}
              backgroundColor={getBackgroundColor(category)}
              onClick={() => handleClick(category)}
            >
              <S.ImageContainer>
                <NextImage
                  src={categoryLogoUrl(category.logoUrl)}
                  alt={category.name}
                  width="100%"
                  height="100%"
                />
              </S.ImageContainer>
              <S.CategoryName>{category.name}</S.CategoryName>
            </S.CategoryCard>
          );
        })}
      </S.CategoryGrid>
    </S.Wrapper>
  );
};
