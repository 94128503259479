import { accountAboyeur } from "@app/domains/account/events";
import { useAddress } from "@app/domains/address/context";
import { useAuthentication } from "@app/domains/authentication/context";
import { useCheckout } from "@app/domains/checkout/context";
import { useMerchant } from "@app/domains/merchant/context";
import {
  ChevronDown,
  ChevronUp,
  ImageWithFallback,
  Bag,
} from "@app/domains/shared/design-system";
import { useRouter } from "next/router";
import { useMemo, useState } from "react";

import { SearchBar, NavbarCategory } from "@app/domains/catalog/views";
import { SuspendedMenu } from "@app/domains/account/views";

import * as S from "./styles";
import { Flex } from "@ifood/pomodoro-components";

export const Header: React.VFC = () => {
  const router = useRouter();
  const { merchant } = useMerchant();
  const { order, setCheckoutDetailsStatus } = useCheckout();
  const { customer, openIfoodAuthentication } = useAuthentication();
  const { getAddress, deliveryMethod } = useAddress();
  const [isSuspendedMenuOpen, setIsSuspendedMenuOpen] = useState(false);

  const address = useMemo(() => getAddress("MERCHANT"), [getAddress]);

  const logo = merchant.getLogoUrl();

  const totalQuantity = useMemo(() => {
    if (!order.itemsList.length) return "0 itens";
    const quantity = order.itemsList.reduce(
      (acc, item) => acc + item.quantity,
      0,
    );
    return `${quantity} ${quantity > 1 ? "itens" : "item"}`;
  }, [order.itemsList]);

  function handleRedirectToCatalog() {
    accountAboyeur.events.header.logo();
    router.push(`/${merchant.query}`);
  }

  function handleSwitchStore() {
    if (!merchant.getChain()?.slug) return;
    router.push({
      pathname: "/[chain]",
      query: { chain: merchant.getChain()?.slug },
    });
  }

  function handleOpenBag() {
    setCheckoutDetailsStatus("OPEN");
  }

  function handleOpenIfoodAuthentication() {
    accountAboyeur.events.header.login();
    openIfoodAuthentication("EMAIL", "header");
  }

  function handleToogleSuspendedMenu() {
    if (!customer) return;
    if (isSuspendedMenuOpen) {
      closeSuspendedMenu();
    } else {
      openSuspendedMenu();
    }
  }

  function closeSuspendedMenu() {
    accountAboyeur.events.headerSuspendedMenu.close();
    setIsSuspendedMenuOpen(false);
  }

  function openSuspendedMenu() {
    accountAboyeur.events.headerSuspendedMenu.open();
    setIsSuspendedMenuOpen(true);
  }

  return (
    <>
      <S.WrapperContainer>
        <S.Wrapper>
          <Flex
            gap="24px"
            alignItems="center"
            onClick={handleRedirectToCatalog}
          >
            <S.Logo>
              <ImageWithFallback
                src={logo}
                fallbackSrc="/images/no_image.png"
                layout="fill"
                objectFit="contain"
                alt={merchant.name}
              />
            </S.Logo>
            <Flex flexDirection="column">
              <S.MerchantName>{merchant.name}</S.MerchantName>
              <S.MinimumOrderValue>
                Pedido mínimo{" "}
                {merchant.minimumOrderValue.format().replace(",00", "")}
              </S.MinimumOrderValue>
            </Flex>
          </Flex>

          {/* <S.HeaderItem onClick={handleSwitchStore}>
            <S.SwitchIcon component={Switch} size="xs" />
            <S.SwitchMerchant>Trocar loja </S.SwitchMerchant>
          </S.HeaderItem> */}

          <SearchBar />

          <S.Profile>
            {customer?.user && (
              <>
                <S.Container onClick={handleToogleSuspendedMenu}>
                  {isSuspendedMenuOpen ? (
                    <S.Icon component={ChevronUp} size="s" />
                  ) : (
                    <S.Icon component={ChevronDown} size="s" />
                  )}
                  <Flex flexDirection="column" gap="4px">
                    <S.UserName>{customer.user.name}</S.UserName>
                    {deliveryMethod &&
                      deliveryMethod.isDeliveryMode() &&
                      address && (
                        <S.UserAddress>
                          {address.streetName}, {address.streetNumber}
                        </S.UserAddress>
                      )}
                  </Flex>
                </S.Container>
                {isSuspendedMenuOpen && (
                  <S.SuspendedMenuArea>
                    <SuspendedMenu onClose={closeSuspendedMenu} />
                  </S.SuspendedMenuArea>
                )}
              </>
            )}
            {!customer && (
              <S.Container onClick={handleOpenIfoodAuthentication}>
                <S.Text color="primary" fontWeight="500">
                  Entre ou cadastre-se
                </S.Text>
              </S.Container>
            )}
          </S.Profile>

          <S.BagItem onClick={handleOpenBag} data-testid="bag-button">
            <S.Icon component={Bag} width="18px" height="18px" />
            <S.BagWrapper>
              <S.BagPrice>{order.totalItems.format()}</S.BagPrice>
              <S.BagCounter>{totalQuantity}</S.BagCounter>
            </S.BagWrapper>
          </S.BagItem>
        </S.Wrapper>
      </S.WrapperContainer>
      <NavbarCategory></NavbarCategory>
    </>
  );
};
