import styled, { DefaultTheme } from "styled-components";
import { maxWidth } from "@app/utils/viewport";
import { Z_INDEXES } from "@app/domains/shared/constants";

const belowDesktop = ({ theme }: { theme: DefaultTheme }) =>
  `max-width: ${theme.screens.m.max}`;

export const FixedHeader = styled.header`
  position: sticky;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 2;
  transition: all 0.5s ease;
`;

export const ContentWrapper = styled.div`
  padding: 0 ${({ theme }) => theme.space.loose};
  margin-top: ${({ theme }) => theme.space.larger};
  margin-bottom: ${({ theme }) => theme.space.largest};
  justify-content: center;
  display: flex;
  flex: auto;
  @media (${belowDesktop}) {
    padding: 0;
    margin-top: ${({ theme }) => theme.space.regular};
    margin-bottom: ${({ theme }) => theme.space.larger};
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: ${maxWidth};
  position: relative;
  bottom: 0;
  flex: auto;
`;

export const BottomFloatingStack = styled.div`
  position: sticky;
  bottom: 0px;
  left: 0;
  z-index: ${Z_INDEXES.BOTTOM_FLOATING_STACK};

  width: 100%;
  display: none;
  flex-direction: column;

  @media (${belowDesktop}) {
    display: flex;
  }
`;

export const LoadingWrapper = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100dvw;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
`;
