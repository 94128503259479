import { MessageFilled } from "@ifood/pomodoro-icons";
import { useMerchant } from "@app/domains/merchant/context";
import {
  Divider,
  Drawer,
  Flex,
  Heading,
  Icon,
  Text,
} from "@app/domains/shared/design-system";
import NextImage from "next/image";
import React, { useState } from "react";

import { orderAboyeur } from "@app/domains/order/events";
import { useHasRatedOrder } from "@app/domains/order/hooks";
import { APP_RATING, MERCHANT_RATING } from "@app/domains/order/constants";

import { Star } from "../Star";
import Options from "./Options";
import * as S from "./styles";
import Success from "./Success";

const STARTS_RATING_LABELS = {
  1: "Péssimo",
  2: "Ruim",
  3: "Pode Melhorar",
  4: "Bom ",
  5: "Muito bom",
};

export type RatingDrawerProps = {
  applicationName?: string;
  rating: number;
  onClose: () => void;
  isMerchantRating: boolean;
  orderId: string;
};

function getRatingSubtitle(rating: number) {
  return STARTS_RATING_LABELS[rating];
}

function getStarKey(index: number) {
  return `star-${index}`;
}

export const RatingDrawer: React.VFC<RatingDrawerProps> = ({
  applicationName,
  rating,
  onClose,
  isMerchantRating,
  orderId,
}) => {
  const [reviewOptions, setReviewOptions] = useState<string[]>([]);
  const [comment, setComment] = useState<string>("");
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [, setHasRated] = useHasRatedOrder(orderId);
  const { merchant } = useMerchant();

  const { GOOD_RATING_OPTIONS, BAD_RATING_OPTIONS, title } = isMerchantRating
    ? MERCHANT_RATING
    : APP_RATING;

  const logo = merchant.getLogoUrl();

  function handleSubmit() {
    orderAboyeur.events.orderRating.rate({
      rating,
      comment,
      order_id: orderId,
      merchant_id: merchant.id,
      options: reviewOptions.join(),
    });
    setHasSubmitted(true);
  }

  function handleClose() {
    if (hasSubmitted) {
      setHasRated(true);
    }

    onClose();
  }

  return (
    <Drawer open={true} onClose={handleClose} title="Avaliação">
      <S.Wrapper>
        {!hasSubmitted && (
          <>
            <S.Logo>
              {logo && (
                <NextImage
                  src={logo}
                  width="80px"
                  height="80px"
                  alt={merchant.name}
                />
              )}
            </S.Logo>
            <Heading as="h3" variant="small" mt={0} mb="small">
              {title(applicationName)}
            </Heading>
            <S.StarWrapper>
              <S.StarsContainer>
                {Array.from({ length: 5 }).map((_, index) => (
                  <Star key={getStarKey(index)} filled={index < rating} />
                ))}
              </S.StarsContainer>
              <S.RatingText>{getRatingSubtitle(rating)}</S.RatingText>
            </S.StarWrapper>
            <Divider my="regular" width="90%" />
            {rating > 3 && (
              <Options
                onChange={setReviewOptions}
                options={GOOD_RATING_OPTIONS}
                title="Do que você gostou?"
              />
            )}
            {rating < 4 && (
              <Options
                onChange={setReviewOptions}
                options={BAD_RATING_OPTIONS}
                title="O que pode melhorar?"
              />
            )}
            <S.CommentWrapper>
              <Flex alignItems="center" width="100%" mb="smaller">
                <Icon
                  component={MessageFilled}
                  width="15px"
                  height="15px"
                  mr="smaller"
                />
                <Text>Deixar comentário</Text>
              </Flex>
              <S.TextAreaInput
                placeholder="Conte-nos como foi sua experiência (opcional)."
                rows={1}
                onChange={(e) => setComment(e.target.value)}
                data-testid="ratingComment"
              />
              <S.ConfirmButton
                variant="primary"
                mt="regular"
                onClick={handleSubmit}
              >
                Confirmar avaliação
              </S.ConfirmButton>
            </S.CommentWrapper>
          </>
        )}
        {hasSubmitted && <Success rating={rating} onClose={handleClose} />}
      </S.Wrapper>
    </Drawer>
  );
};
