import { snackbar } from "@ifood/pomodoro-components";
import { useAddress } from "@app/domains/address/context";
import { checkoutAboyeur } from "@app/domains/checkout/events";
import { useMerchant } from "@app/domains/merchant/context";
import { DeliveryMethod } from "@app/domains/merchant/models";
import { useCheckout } from "@app/domains/checkout/context";

export const useUpdateDeliveryMethod = () => {
  const { merchant } = useMerchant();
  const { order, updateOrder } = useCheckout();
  const {
    deliveryMethod: persistedDeliveryMethod,
    getAddress,
    setDeliveryMethod,
  } = useAddress();

  async function updateDeliveryMethod(deliveryMethod?: DeliveryMethod) {
    try {
      if (deliveryMethod) {
        setDeliveryMethod(deliveryMethod);
        return updateOrder((prevOrder) =>
          prevOrder.withDeliveryMethod(deliveryMethod),
        );
      }

      if (order.isDefaultDeliveryMethod()) {
        const customerAddress = getAddress("MERCHANT");
        const deliveryMethodResponse = await merchant.getDeliveryMethod(
          customerAddress.coordinates.latitude,
          customerAddress.coordinates.longitude,
        );
        if (!deliveryMethodResponse) return;
        deliveryMethod = DeliveryMethod.fromApi(deliveryMethodResponse);
      } else {
        const takeoutMethodResponse = await merchant.getTakeoutMethod();
        if (!takeoutMethodResponse) return;
        deliveryMethod = DeliveryMethod.fromApi(takeoutMethodResponse);
      }

      const selectedTimeSlotId =
        persistedDeliveryMethod.schedule.selectedTimeSlot?.id;

      if (selectedTimeSlotId) {
        const timeSlot = deliveryMethod.schedule.timeSlots.find(
          (timeSlot) => timeSlot.id === selectedTimeSlotId,
        );
        deliveryMethod.schedule.selectedTimeSlot = timeSlot;
      }

      setDeliveryMethod(deliveryMethod);
      updateOrder((prevOrder) => prevOrder.withDeliveryMethod(deliveryMethod));
    } catch (error: any) {
      snackbar({ variant: "error", message: error.message });
      checkoutAboyeur.events.catch.onError(error);
    }
  }

  return { updateDeliveryMethod };
};
