import {
  Text as PomodoroText,
  Heading as PomodoroHeading,
} from "@app/domains/shared/design-system";
import { maxWidth } from "@app/utils/viewport";
import styled from "styled-components";

const desktop = ({ theme }: { theme: any }) =>
  `min-width: ${theme.screens.l.min}`;
const belowDesktop = ({ theme }: { theme: any }) =>
  `max-width: ${theme.screens.m.max}`;

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  background-color: #fff;
  max-width: ${maxWidth};
  position: relative;
  margin-top: -${({ theme }) => theme.space.regular};

  @media (${desktop}) {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: #fff;
  gap: ${({ theme }) => theme.space.regular};
  margin-bottom: ${({ theme }) => theme.space.regular};
  margin-top: ${({ theme }) => theme.space.smallest};
  padding: ${({ theme }) => theme.space.regular};
  @media (${belowDesktop}) {
    margin-bottom: ${({ theme }) => theme.space.none};
    padding-bottom: ${({ theme }) => theme.space.none};
  }
`;

export const Info = styled.div`
  display: flex;
  gap: 16px;
`;

export const Logo = styled.div`
  position: relative;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
  border: solid 1px ${({ theme }) => theme.colors.grayLight};
`;

export const Text = styled(PomodoroText)`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme, color }) => color || theme.colors.grayDarkest};
  line-height: 1.3;
`;

export const Heading = styled(PomodoroHeading).attrs({
  forwardedAs: "h1",
})`
  font-family: ${({ theme }) => theme.font.family.ifood.body};
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SearchContainer = styled.div`
  [role="combobox"] > :first-child {
    input {
      padding: 10px 16px;
    }

    svg path {
      color: ${({ theme }) => theme.colors.black} !important;
    }
  }
`;
