import {
  Order as CheckoutOrder,
  OrderCheckoutResponse,
  OrderPayload,
} from "@app/domains/checkout/models";
import { ecommerceEvents } from "@app/domains/shared/ecommerce-events";
import { useCallback, useState } from "react";
import { ERRORS } from "@app/domains/checkout/constants";
import { useCheckout } from "@app/domains/checkout/context";
import { useResetOrder } from "@app/domains/checkout/hooks";

type Options = {
  onError: (err: Error) => void;
  onSuccess: (data: {
    orderResponse: OrderCheckoutResponse;
    orderPayload: OrderPayload;
  }) => void;
};

export function useCreateOrder({ onError, onSuccess }: Options) {
  const { order } = useCheckout();
  const resetOrder = useResetOrder();
  const [loading, setLoading] = useState(false);

  const createOrder = useCallback(
    async function () {
      try {
        const data = await order.sendOrder();
        ecommerceEvents.doCheckout(order, data.orderResponse.id);
        onSuccess(data);
        resetOrder();
      } catch (error: any) {
        onError(error);
      }
    },
    [order, onError, onSuccess, resetOrder],
  );

  const startOrderCreation = useCallback(async () => {
    setLoading(true);

    try {
      const isCheckoutEnabled = await CheckoutOrder.getIsCheckoutEnabled();

      if (!isCheckoutEnabled) {
        throw new Error(ERRORS.CHECKOUT_DISABLED);
      }

      await createOrder();

      setLoading(false);
    } catch (error: any) {
      onError(error);
      setLoading(false);
    }
  }, [createOrder, onError]);

  return { loading, startOrderCreation };
}
