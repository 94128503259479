import { Merchant } from "@app/domains/merchant/models";
import { ChatOutlined, TextLink } from "@app/domains/shared/design-system";
import { useMemo } from "react";

import * as S from "./styles";

type FAQProps = {
  merchant: Merchant;
};

export const FAQ: React.VFC<FAQProps> = ({ merchant }) => {
  const FAQLink = useMemo(() => merchant.getFAQLink(), [merchant]);

  if (!FAQLink) return null;

  return (
    <S.Container>
      <S.Icon component={ChatOutlined} size="s" />
      <a href={FAQLink} target="_blank" rel="noopener noreferrer">
        <TextLink>Perguntas frequentes</TextLink>
      </a>
    </S.Container>
  );
};
