import { Aboyeur } from "@ifood/aboyeur";
import { getMerchantIdFromUrl } from "@app/domains/shared/url-utils";

type RatingProps = {
  merchant_id: string;
  rating: number;
  options: string;
  comment: string;
  order_id: string;
};

export const orderRevision = 1;

export const orderAboyeur = new Aboyeur("order", {
  catch: {
    /**
     * @when Unexpected errors happened
     */
    onError: (error: Error) => ({
      label: "error",
      metadata: { error: error.message, merchant_id: getMerchantIdFromUrl() },
    }),
  },
  delivery_unavailable: {
    /**
     * @when The user clicks to ok button
     */
    clickOkIGotIt: (merchant_id: string) => ({
      label: "ok_i_got_it",
      metadata: { merchant_id },
    }),
  },
  share: {
    /**
     * @when The user clicks shares the order successfully
     */
    click: (value: string) => ({
      label: "click",
      metadata: { value, merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user clicks on open share
     */
    openOrderShare: () => ({
      label: "open_order_share",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user clicks on close share
     */
    closeOrderShare: () => ({
      label: "close_order_share",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
  },
  help: {
    /**
     * @when The user open the help modal
     */
    openModal: () => ({
      label: "open_modal",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user close the help modal
     */
    closeModal: () => ({
      label: "close_modal",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user clicks to call the merchant
     */
    call: (merchant_id: string) => ({
      label: "call",
      metadata: { merchant_id },
    }),

    /**
     * @when The user clicks to go to the whatsapp chat with the merchant
     */
    whatsapp: (merchant_id: string) => ({
      label: "whatsapp",
      metadata: { merchant_id },
    }),
  },
  pix: {
    /**
     * @when The user open the pix modal
     */
    openModal: () => ({
      label: "open_modal",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user close the pix modal
     */
    closeModal: () => ({
      label: "close_modal",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user copy the pix code
     */
    copy: () => ({
      label: "copy_pix_code",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user open the pix help modal
     */
    openHelpModal: () => ({
      label: "open_help_modal",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user close the pix help modal
     */
    closeHelpModal: () => ({
      label: "close_help_modal",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
  },
  orderDetails: {
    /**
     * @when The user open the order details
     */
    open: () => ({
      label: "open",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user close the order details
     */
    close: () => ({
      label: "close",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
  },
  FAQs: {
    /**
     * @when The user open the FAQs list modal
     */
    openModal: () => ({
      label: "open_modal",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user close the FAQs list modal
     */
    closeModal: () => ({
      label: "close_modal",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
  },
  FAQ: {
    /**
     * @when The user open the FAQ modal
     */
    openModal: (question: string) => ({
      label: "open_modal",
      metadata: { question, merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user close the FAQ modal
     */
    closeModal: () => ({
      label: "close_modal",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
  },
  orderRating: {
    /**
     * @when The user open the order rating modal
     */
    openModal: (merchant_id: string, rating: number) => ({
      label: "open_order_rating_modal",
      metadata: { merchant_id, rating },
    }),
    /**
     * @when The user close the order rating modal
     */
    closeModal: (merchant_id: string) => ({
      label: "close_order_rating_modal",
      metadata: { merchant_id },
    }),
    /**
     * @when The user rate the order
     */
    rate: ({
      merchant_id,
      rating,
      options,
      comment,
      order_id,
    }: RatingProps) => ({
      label: "rate_order",
      metadata: {
        merchant_id,
        rating,
        options,
        comment,
        order_id,
      },
    }),
  },
  userOpinionSurvey: {
    /**
     * @when The user opinion survey dialog open
     */
    view: (merchant_id: string) => ({
      label: "view_survey",
      metadata: { merchant_id },
    }),
    /**
     * @when The user click to open survey
     */
    open: (merchant_id: string) => ({
      label: "open_survey",
      metadata: { merchant_id },
    }),
    /**
     * @when The user click to not answer survey
     */
    close: (merchant_id: string) => ({
      label: "close_survey",
      metadata: { merchant_id },
    }),
    /**
     * @when Error occurs on get survey info
     */
    onError: (error: Error) => ({
      label: "error_get_survey",
      metadata: { error: error.message, merchant_id: getMerchantIdFromUrl() },
    }),
  },
  orderTrackingList: {
    /**
     * @when Doesn't have orders with mapped status to show
     */
    empty: (orders: { id: string; lastStatus: string }[]) => ({
      label: "orders_tracking_empty",
      metadata: {
        orders: JSON.stringify(orders),
        merchant_id: getMerchantIdFromUrl(),
      },
    }),
    /**
     * @when The user sees order list
     */
    viewOrderList: () => ({
      label: "view_order_list",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
  },
  orderStatus: {
    /**
     * @when The user sees order progress bar aka waiting
     */
    viewWaiting: () => ({
      label: "view_waiting",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
  },
}).withMetadata({
  revision: orderRevision,
});
