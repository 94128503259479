import { useCheckout } from "@app/domains/checkout/context";
import { useMerchant } from "@app/domains/merchant/context";
import { useMemo } from "react";

export const useOrderValidator = () => {
  const { order } = useCheckout();
  const { merchant } = useMerchant();
  const { paymentMethod, totalOrder } = order;

  const paymentValidate = useMemo<boolean>(() => {
    if (
      !paymentMethod ||
      (paymentMethod.method.name === "CASH" &&
        paymentMethod.cashChange &&
        paymentMethod.cashChange.getValue() < totalOrder.getValue())
    )
      return false;
    return true;
  }, [paymentMethod, totalOrder]);

  const isDocumentRequiredButMissing =
    merchant.isDocumentRequired && !order.document;
  const isPaymentInvalid = !paymentValidate;
  const isMissingDeliveryMethod = !order.deliveryMethod;
  const isMissingValue =
    order.totalItems.getValue() < merchant.minimumOrderValue.getValue();

  return {
    isDocumentRequiredButMissing,
    isMissingDeliveryMethod,
    isPaymentInvalid,
    isMissingValue,
  };
};
