import { Dialog, DialogProps } from "@app/domains/shared/design-system";

export const Desktop: React.FC<DialogProps> = ({
  children,
  ...dialogProps
}) => {
  return (
    <Dialog {...dialogProps}>
      <Dialog.Body>{children}</Dialog.Body>
    </Dialog>
  );
};
