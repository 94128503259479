import { Button as PomodoroButton } from "@app/domains/shared/design-system";
import styled from "styled-components";

export const Button = styled(PomodoroButton)`
  width: 100%;

  font-size: ${({ theme }) => theme.fontSizes.s};
  text-decoration: none;

  &:first-child {
    margin-top: ${({ theme }) => theme.space.small};
  }

  @media (min-width: 465px) {
    &:first-child {
      margin-top: ${({ theme }) => theme.space.none};
    }
  }
`;
