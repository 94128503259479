import {
  Heading,
  Icon as PomodoroIcon,
  Text,
} from "@app/domains/shared/design-system";
import styled, { DefaultTheme } from "styled-components";

const belowDesktop = ({ theme }: { theme: DefaultTheme }) =>
  `max-width: ${theme.screens.m.max}`;

export const Wrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space.regular};
  border-radius: ${({ theme }) => theme.radii.m};
`;

export const Icon = styled(PomodoroIcon)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  gap: ${({ theme }) => theme.space.regular};
  align-items: center;
  justify-content: space-between;

  @media (${belowDesktop}) {
    flex-direction: column;
    align-items: start;
  }
`;

export const SwitchAddress = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  cursor: pointer;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const DeliveryAddress = styled(Heading)`
  font-weight: 500;
  margin: ${({ theme }) => theme.space.none};
  font-size: ${({ theme }) => theme.fontSizes.txl};
  line-height: ${({ theme }) => theme.lineHeights.m};
  margin-bottom: ${({ theme }) => theme.space.smaller};

  @media (${belowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.s};
  }
`;

export const DeliveryFee = styled(Text)`
  color: ${({ theme }) => theme.colors.grayDarkest};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: ${({ theme }) => theme.lineHeights.s};
`;

type DeliveryModeProps = {
  active?: boolean;
  pl?: string;
  pr?: string;
};

export const ToogleDeliveryMode = styled.div<DeliveryModeProps>`
  cursor: pointer;
  font-weight: 500;
  font-family: ${({ theme }) => theme.font.family.ifood.headings};
  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: ${({ theme }) => theme.fontSizes.xl};

  padding-left: ${({ pl }) => pl};
  padding-right: ${({ pr }) => pr};

  --primaryColor: ${({ theme }) => theme.colors.primary};
  --gray: ${({ theme }) => theme.colors.gray};
  --color: ${({ active }) => (active ? "var(--primaryColor)" : "var(--gray)")};
  color: var(--color);
  border-bottom: solid 2px var(--color);

  transition: all 200ms;

  &:hover {
    filter: ${({ active }) => (active ? "brightness(1)" : "brightness(0.8)")};
  }

  @media (${belowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.s};
  }
`;
