import {
  Item as CatalogItem,
  ItemJSON as CatalogItemJSON,
} from "@app/domains/catalog/models";
import cloneDeep from "lodash.clonedeep";

export type ItemStatus = "AVAILABLE" | "UNAVAILABLE" | "PRICE_CHANGED";

export type ItemUnit = "UNIT" | "WEIGHT";

export type PayloadItem = {
  id: string;
  code: string;
  description: string;
  unitPrice: number;
  promotionalPrice?: number;
  tags: string[];
  obs: string;
  qty: number;
};

export type ItemJSON = {
  id: string;
  catalogItem: CatalogItemJSON;
  quantity: number;
  unit: ItemUnit;
  observation?: string;
  status?: ItemStatus;
};

export class Item {
  static fromCatalogItem(
    item: CatalogItem,
    quantity: number,
    unit?: ItemUnit,
    observation?: string,
    status?: ItemStatus,
  ): Item {
    const { id } = item;

    return new Item(id, cloneDeep(item), quantity, unit, observation, status);
  }

  static fromJSON(rawItem: ItemJSON) {
    const catalogItem = CatalogItem.fromJSON(rawItem.catalogItem);

    return new Item(
      rawItem.id,
      catalogItem,
      rawItem.quantity,
      rawItem.unit,
      rawItem.observation,
      rawItem.status,
    );
  }

  constructor(
    public id: string,
    public catalogItem: CatalogItem,
    public quantity: number,
    public unit: ItemUnit = "UNIT",
    public observation: string = "",
    public status: ItemStatus = "AVAILABLE",
  ) {}

  toJSON(): ItemJSON {
    return {
      id: this.id,
      catalogItem: this.catalogItem.toJSON(),
      quantity: this.quantity,
      unit: this.unit,
      observation: this.observation,
      status: this.status,
    };
  }

  calculateOriginalPrice() {
    return this.catalogItem.calculateOriginalPrice(this.quantity);
  }

  calculatePrice() {
    return this.catalogItem.calculatePrice(this.quantity);
  }

  withQuantity(quantity: number): this {
    this.quantity = quantity;
    return this;
  }

  toPayload(): PayloadItem {
    return {
      id: this.catalogItem.id,
      code: this.catalogItem.code,
      description: this.catalogItem.description,
      qty: this.quantity,
      unitPrice: this.catalogItem.unitPrice.getValue(),
      promotionalPrice: this.catalogItem.promotionalPrice?.getValue(),
      obs: this.observation,
      tags:
        this.catalogItem.tags?.filter((tag) => !tag.includes("CART::")) ?? [],
    };
  }
}
