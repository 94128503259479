import { Search as PomodoroSearch } from "@app/domains/shared/design-system";
import styled from "styled-components";

export const Search = styled(PomodoroSearch)`
  font-size: ${({ theme }) => theme.fontSizes.tsm};
  background-color: #f7f7f7;
  padding: 12px;
`;

export const Wrapper = styled.div`
  flex-grow: 1;

  div:has(> [role="combobox"]) {
    position: relative;

    > :last-child {
      width: calc(100% - 2px) !important;

      :not(:has(li)) {
        border: solid 1px transparent;
      }
    }
  }

  [role="combobox"] > :first-child {
    background-color: #f7f7f7;
    border-radius: 8px;
    border-color: #ebebeb;
    width: calc(100% - 2px);
    margin: auto;

    > :first-child {
      background-color: #f7f7f7;
      border-color: #f7f7f7;

      div:first-child {
        margin-left: 10px;
        padding-right: 0px;
      }

      input {
        background-color: #f7f7f7;
      }
    }

    > :last-child {
      margin-top: 1px;
    }
  }

  [role="listbox"] li {
    padding-right: 16px;
    padding-left: 16px;
    font-size: ${({ theme }) => theme.fontSizes.tsm};
  }
`;
