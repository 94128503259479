import { Z_INDEXES } from "@app/domains/shared/constants";
import {
  DrawerWithPush,
  DrawerWithPushProps,
} from "@app/domains/shared/design-system";

import * as S from "./styles";

export const Mobile: React.FC<DrawerWithPushProps> = ({
  children,
  title,
  ...props
}) => {
  return (
    <DrawerWithPush
      {...props}
      title={<S.HelpText>{title}</S.HelpText>}
      zIndex={Z_INDEXES.HELP_DIALOG}
    >
      {children}
    </DrawerWithPush>
  );
};
