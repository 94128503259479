import { Flex } from "@app/domains/shared/design-system";
import styled, { DefaultTheme } from "styled-components";

const belowDesktop = ({ theme }: { theme: DefaultTheme }) =>
  `max-width: ${theme.screens.m.max}`;

export const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: ${({ theme }) => theme.space.regular};
  margin: auto 0;

  button {
    width: 340px;
    font-size: 16px;
    font-weight: bold;

    @media (${belowDesktop}) {
      width: auto;
    }
  }
`;
