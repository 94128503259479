import { useMerchant } from "@app/domains/merchant/context";
import { Heading, Text } from "@app/domains/shared/design-system";
import { useRouter } from "next/router";
import SVG from "react-inlinesvg";

import * as S from "./styles";

export const OrderNoted = () => {
  const router = useRouter();
  const { merchant } = useMerchant();

  function redirectToOrdersTrackPage() {
    router.push(`/${merchant.query}/order`);
  }

  return (
    <S.Container>
      <SVG src="/images/checkout/order_noted.svg" />
      <Heading fontWeight={400} variant="small" textAlign="center" as="h1">
        Pedido enviado
      </Heading>
      <Text textAlign="center" color="grayDarkest">
        Recebemos seu pedido e agora você pode acompanhá-lo até a entrega ou
        retirada
      </Text>
      <S.FollowOrderButton mt={56} onClick={redirectToOrdersTrackPage}>
        Acompanhar pedido
      </S.FollowOrderButton>
    </S.Container>
  );
};
