import { Button, Input } from "@app/domains/shared/design-system";
import { useState } from "react";
import { useCheckout } from "@app/domains/checkout/context";
import { Flex, Heading } from "@ifood/pomodoro-components";

import * as S from "./styles";

type ConfirmCVVProps = {
  onBack: () => void;
  onConfirm: () => void;
};

const CVV_LENGTH = 3;

export const ConfirmCVV: React.VFC<ConfirmCVVProps> = ({
  onBack,
  onConfirm,
}) => {
  const [cvv, setCVV] = useState("");
  const { order, setCardToken } = useCheckout();

  function handleSubmit() {
    setCardToken(
      {
        ...order.cardToken,
        cvv,
      },
      order.paymentMethod,
    );

    onConfirm();
  }

  return (
    <S.Wrapper>
      <Heading fontSize="20px" lineHeight="24px" mt="0" mb="small">
        Confirmar código de segurança
      </Heading>
      <Input
        onChange={(e) => setCVV(e.target.value)}
        placeholder="CVV"
        type="tel"
        maxLength={CVV_LENGTH}
      />
      <Flex justifyContent="space-between">
        <Button color="primary" variant="primary-inverted" onClick={onBack}>
          Voltar
        </Button>
        <Button onClick={handleSubmit} disabled={cvv.length !== CVV_LENGTH}>
          Confirmar
        </Button>
      </Flex>
    </S.Wrapper>
  );
};
