import { Close, Dialog } from "@app/domains/shared/design-system";

import { WrapperToRenderProps } from "../types";
import * as S from "./styles";

export const Desktop: React.FC<WrapperToRenderProps> = ({
  children,
  isOpen,
  title,
  onClose,
  ...props
}) => {
  return (
    <Dialog open={isOpen} {...props}>
      <S.Header>
        <S.Title>{title}</S.Title>
        <S.Icon
          component={Close}
          size="s"
          onClick={onClose}
          width="18"
          height="18"
        />
      </S.Header>
      <S.Body>{children}</S.Body>
    </Dialog>
  );
};
