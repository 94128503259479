import { Heading, Text, TextLink } from "@app/domains/shared/design-system";
import NextImage from "next/image";
import React from "react";

interface Props {
  rating: number;
  onClose: () => void;
}

const variations = {
  good: {
    image: "/images/order/good-rating.svg",
    title: "Agradecemos pela avaliação!",
    description:
      "Sua opinião é muito importante pra melhorarmos cada vez mais a experiência.",
  },
  bad: {
    image: "/images/order/bad-rating.svg",
    title: "Poxa, sentimos muito pela experiência ruim",
    description:
      "Sua opinião é muito importante para melhoramos nossas ferramentas.",
  },
};

const Success: React.VFC<Props> = ({ rating, onClose }) => {
  const { image, title, description } = variations[rating > 3 ? "good" : "bad"];

  return (
    <div>
      <NextImage src={image} width="235px" height="190px" />
      <Heading as="h4" variant="small" mt={0} mb="small">
        {rating === 5 && "Eba! "}
        {title}
      </Heading>
      <Text color="grayDarkest">{description}</Text>
      <TextLink mt="large" onClick={onClose}>
        Fechar
      </TextLink>
    </div>
  );
};

export default Success;
