import { useAuthentication } from "@app/domains/authentication/context";
import { Heading, Text } from "@app/domains/shared/design-system";
import SVG from "react-inlinesvg";

import * as S from "./styles";
import { useMerchant } from "@app/domains/merchant/context";
import { useRouter } from "next/router";

export const NoUserLoggedFound: React.VFC = () => {
  const { openIfoodAuthentication } = useAuthentication();
  const { merchant } = useMerchant();
  const router = useRouter();

  const redirectToHomeMerchant = () => {
    router.push(`/${merchant.query}`);
  };

  const handleAuthentication = () => {
    return openIfoodAuthentication("EMAIL", "order", {
      callback: redirectToHomeMerchant,
    });
  };

  return (
    <S.Container>
      <SVG src="/images/order/empty_state.svg" width={200} height={200} />
      <Heading as="h2" textAlign="center" variant="regular" mt={32} mb={6}>
        Você não está logado
      </Heading>
      <Text textAlign="center" variant="regular" color="grayDarkest">
        Entre ou cadastre-se para aproveitar ao máximo nosso cardápio
      </Text>
      <S.Button variant="primary" onClick={handleAuthentication}>
        Entrar ou cadastrar-se
      </S.Button>
    </S.Container>
  );
};
