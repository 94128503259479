import {
  Flex,
  Button as PomodoroButton,
  Heading as PomodoroHeading,
  Text,
} from "@app/domains/shared/design-system";
import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
`;

export const Heading = styled(PomodoroHeading).attrs({ variant: 'small' })`
  margin-top: ${({ theme }) => theme.space.regular};
  color: ${({ theme }) => theme.colors.black};
  font-weight: 500;
`;

export const Step = styled(Flex).attrs({ flexDirection: 'column' })`
  margin-top: ${({ theme }) => theme.space.regular};
  padding-left: ${({ theme }) => theme.space.large};
  padding-right: ${({ theme }) => theme.space.large};
`;

export const StepHeading = styled(Text).attrs({ variant: 'regular' })`
  margin-bottom: ${({ theme }) => theme.space.smaller};
  font-weight: 500;
`;

export const StepDescription = styled(Text).attrs({ variant: 'regular' })`
  color: ${({ theme }) => theme.colors.grayDarkest};
`;

export const Button = styled(PomodoroButton).attrs({ variant: 'primary' })`
  width: 100%;
  margin-top: ${({ theme }) => theme.space.large};
`;
