import { useMerchant } from "@app/domains/merchant/context";
import { List } from "../shared/List";
import { PriceSummary } from "../shared/PriceSummary";
import { DocumentSelect } from "./components/DocumentSelect";
import { DeliverySelect } from "./components/DeliverySelect";
import { TimeSlotSelect } from "./components/TimeSlotSelect";
import { PaymentSelect } from "./components/PaymentSelect";

import * as S from "./styles";
import { createRef } from "react";
import { snackbar } from "@ifood/pomodoro-components";
import { checkoutAboyeur } from "@app/domains/checkout/events";
import { useOrderValidator } from "./hooks/useOrderValidator";
import { useIsMobile } from "@app/domains/shared/hooks";
import { useCheckout } from "@app/domains/checkout/context";

type OrderSummaryProps = {
  handleCheckoutClick: () => Promise<void>;
  scrollToEmptyField: (elementRef) => void;
  isLoading: boolean;
};

export const OrderSummary = ({
  handleCheckoutClick,
  scrollToEmptyField,
  isLoading,
}: OrderSummaryProps) => {
  const isMobile = useIsMobile();
  const { order } = useCheckout();
  const paymentSelectRef = createRef<HTMLDivElement>();
  const documentSelectRef = createRef<HTMLDivElement>();
  const selectedTimeSlot = order.deliveryMethod.schedule?.selectedTimeSlot;
  const hasTimeSlots = Boolean(
    order.deliveryMethod.schedule?.timeSlots?.length,
  );

  const {
    isDocumentRequiredButMissing,
    isMissingDeliveryMethod,
    isPaymentInvalid,
    isMissingValue,
  } = useOrderValidator();

  const isCheckoutButtonDisabled =
    isDocumentRequiredButMissing ||
    isMissingDeliveryMethod ||
    isPaymentInvalid ||
    isMissingValue ||
    !hasTimeSlots;

  const handleCloseOrderClick = () => {
    if (isCheckoutButtonDisabled) {
      handleDisabledOrderButton();
    } else {
      handleCheckoutClick();
    }
  };

  function handleDisabledOrderButton() {
    checkoutAboyeur.events.payment.disabledCheckoutOrderButton();

    if (isPaymentInvalid) {
      checkoutAboyeur.events.payment.viewEmptyPaymentErrorAlert();
      snackbar({
        message: "Escolha a forma de pagamento pra continuar o pedido.",
        variant: "warning",
      });
      scrollToEmptyField(paymentSelectRef);
      return;
    }

    if (isDocumentRequiredButMissing) {
      checkoutAboyeur.events.document.viewEmptyDocumentErrorAlert();
      snackbar({
        message: "Informe o CPF pra continuar o pedido.",
        variant: "warning",
      });
      scrollToEmptyField(documentSelectRef);
      return;
    }

    if (!selectedTimeSlot || !hasTimeSlots) {
      snackbar({
        message: hasTimeSlots
          ? "Nenhum horário para entrega ou retirada foi selecionado."
          : "No momento, não há nenhum horário para agendamento disponível",
        variant: "warning",
      });
    }
  }

  return (
    <S.ColumnWrapper>
      <S.Column>
        <S.CardWrapper>
          <S.CardTitleDesktop fontWeight="700" fontSize="24px">
            Finalize seu pedido
          </S.CardTitleDesktop>

          <S.WhiteCard>
            <DeliverySelect />
            <TimeSlotSelect />
          </S.WhiteCard>
        </S.CardWrapper>

        <S.CardWrapper>
          <S.CardTitle fontWeight="500" fontSize="20px">
            {isMobile ? "Pagamento" : "Escolha a forma de pagamento"}
          </S.CardTitle>

          <S.WhiteCard>
            <PaymentSelect ref={paymentSelectRef} />
          </S.WhiteCard>
        </S.CardWrapper>
      </S.Column>

      <S.Column>
        <S.CardWrapper>
          <S.CardTitle fontWeight="700" fontSize="24px">
            Resumo do pedido
          </S.CardTitle>

          <S.LastWhiteCard>
            <List enableExpandButton={true} />
            <PriceSummary />
            <S.Divider />
            <DocumentSelect ref={documentSelectRef} />
            <S.CheckoutButton
              blocked={isCheckoutButtonDisabled}
              onClick={handleCloseOrderClick}
              isLoading={isLoading}
            >
              Fechar pedido
            </S.CheckoutButton>
          </S.LastWhiteCard>
        </S.CardWrapper>
      </S.Column>
    </S.ColumnWrapper>
  );
};
