/* eslint-disable max-lines */
import { createAuthenticatedAxiosClient } from "@app/domains/authentication/utils";
import { groceriesApiBffURL } from "@app/domains/shared/config";
import { AxiosInstance } from "axios";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";

import { formatDateToHourMinutesString } from "@app/utils/date";

import { Bag, BagResponse } from "./bag";
import {
  DELIVERY_ORDER_STATUS_LABELS,
  ONLINE_DELIVERY_ORDER_STATUS_LABELS,
  ONLINE_TAKEOUT_ORDER_STATUS_LABELS,
  STATUS,
  STATUS_IN_PROGRESS,
  STATUS_IN_PROGRESS_OR_CONCLUDED,
  TAKEOUT_ORDER_STATUS_LABELS,
  initialDeliveryOrderStatuses,
  initialTakeoutOrderStatuses,
  orderInProgressSorter,
} from "./constants";
import { OrderPaymentMethod, PaymentMethod } from "./paymentMethod";

export type OrderStatus = keyof typeof STATUS;
export type OrderStatusInProgress = keyof typeof STATUS_IN_PROGRESS;
export type OrderStatusInProgressOrConcluded =
  keyof typeof STATUS_IN_PROGRESS_OR_CONCLUDED;

export type OrderStatusInfo = {
  value: string;
  label: string;
  updatedAt: string;
  metadata?: Record<string, unknown>;
  isCurrentStatus: boolean;
};

export type Status = {
  id: string;
  value: keyof typeof STATUS;
  createdAt: string;
};

export type OrderStatusResponse = {
  current: Status;
  history: Status[];
};

export type OrderStatuses = {
  [key in OrderStatus]: OrderStatusInfo;
};

export type StatusesResponse = {
  processedAt: string;
  value: OrderStatus;
  metadata?: Record<string, unknown>;
};

export type OrderStatusesResponse = StatusesResponse[];

export type DeliveryAddress = {
  id: string;
  address?: string;
  city: string;
  complement?: string;
  country: string;
  district: string;
  establishment?: string;
  latitude: number;
  longitude: number;
  reference?: string;
  state: string;
  streetNumber?: string;
  zipCode?: string;
};

type Driver = {
  name: string;
};

export type DeliveryResponse = {
  expectedDeliveryTime: string;
  address: DeliveryAddress;
  driver?: Driver;
};

export type PickUpResponse = {
  expectedPickupTime: string;
  address: DeliveryAddress;
};

export type DeliveryMethod = "DELIVERY" | "TAKEOUT";

export type DetailsResponse = {
  mode: DeliveryMethod;
  schedule: boolean;
  trippable: boolean;
  indoorTipEnabled: boolean;
  trackable: boolean;
  boxable: boolean;
  placedAtBox: boolean;
  reviewed: boolean;
  darkKitchen: boolean;
};

export type HandshakeMetadata = {
  handshakeCode?: string;
  handshake?: {
    code?: string;
    sourceOfCode?: string;
  };
  contactless?: boolean;
};

export type PriceResponse = {
  currency: string;
  value: number;
};

export type PricesResponse = {
  grossValue: PriceResponse;
  discount: PriceResponse;
  netValue: PriceResponse;
};

export type OrderOperationMode = {
  type: DeliveryMethod;
  delivery?: {
    destination: DeliveryAddress;
    prices: PricesResponse;
    deliveryTime: {
      window: {
        from: string;
        to: string;
      };
    };
  };
  takeout?: {
    takeoutTime: {
      window: {
        from: string;
        to: string;
      };
    };
  };
};

export type OrderMerchantResponse = {
  id: string;
  name: string;
  timezone: string;
};

export type OrderPaymentsResponse = {
  methods: OrderPaymentMethod[];
  totalValue: PriceResponse;
};

export type OrderResponse = {
  order: {
    id: string;
    shortCode: string;
    createdAt: string;
    operationMode: OrderOperationMode;
    merchant: OrderMerchantResponse;
    bag: BagResponse;
    payment: OrderPaymentsResponse;
  };
} & (
  | { status: Status[]; orderStatus?: never }
  | { orderStatus: OrderStatusResponse; status?: never }
);

function getTakeoutStatusLabel(status: OrderStatus, isOnlinePayment: boolean) {
  if (isOnlinePayment) {
    return ONLINE_TAKEOUT_ORDER_STATUS_LABELS[status];
  }

  return TAKEOUT_ORDER_STATUS_LABELS[status];
}

function getDeliveryStatusLabel(status: OrderStatus, isOnlinePayment: boolean) {
  if (isOnlinePayment) {
    return ONLINE_DELIVERY_ORDER_STATUS_LABELS[status];
  }

  return DELIVERY_ORDER_STATUS_LABELS[status];
}

export class Order {
  static client: AxiosInstance;

  static initClient(): void {
    Order.client = createAuthenticatedAxiosClient(groceriesApiBffURL);
  }

  static fromApi(rawOrder: OrderResponse) {
    const order = rawOrder.order;
    const status = rawOrder.status ?? rawOrder.orderStatus.history;
    const isTakeoutOrder = order.operationMode.type === "TAKEOUT";

    const takeoutFrom = order.operationMode.takeout?.takeoutTime.window.from;
    const takeoutTo = order.operationMode.takeout?.takeoutTime.window.to;
    const pickUpFrom = order.operationMode.delivery?.deliveryTime.window.from;
    const pickUpTo = order.operationMode.delivery?.deliveryTime.window.to;

    const fromTime = isTakeoutOrder ? takeoutFrom : pickUpFrom;
    const toTime = isTakeoutOrder ? takeoutTo : pickUpTo;
    const timezone = order.merchant.timezone;
    const fromDate = toZonedTime(new Date(fromTime), timezone);
    const toDate = toZonedTime(new Date(toTime), timezone);

    const expectedDeliveryDay = format(fromDate, "MM-dd-yyyy");
    const expectedCompletedTime = formatDateToHourMinutesString(fromDate);
    const expectedExtraCompletedTime = formatDateToHourMinutesString(toDate);

    const initialOrderStatuses = isTakeoutOrder
      ? initialTakeoutOrderStatuses
      : initialDeliveryOrderStatuses;

    const deliveryFee = isTakeoutOrder
      ? ({ currency: "BRL", value: 0 } as PriceResponse)
      : order.operationMode.delivery.prices.netValue;
    const orderPaymentMethod = order.payment.methods[0];
    const paymentMethod = PaymentMethod.fromOrderApi(orderPaymentMethod);

    const orderStatuses = {} as OrderStatuses;
    status.forEach((status) => {
      orderStatuses[status.value] = {
        value: status.value,
        label: isTakeoutOrder
          ? getTakeoutStatusLabel(status.value, paymentMethod.isOnlinePayment())
          : getDeliveryStatusLabel(
              status.value,
              paymentMethod.isOnlinePayment(),
            ),
        updatedAt: status.createdAt,
        isCurrentStatus: false,
      };
    });

    return new Order(
      order.id,
      order.shortCode,
      order.shortCode,
      orderStatuses ?? initialOrderStatuses,
      status.at(0).value,
      new Date(order.createdAt),
      new Date(status.at(0).createdAt),
      order.merchant.id,
      Bag.fromApi(order.bag, deliveryFee),
      paymentMethod,
      expectedCompletedTime,
      expectedExtraCompletedTime,
      expectedDeliveryDay,
      order.operationMode.type,
      order.operationMode.delivery?.destination,
      isTakeoutOrder,
    );
  }

  static generateShortId(id: string): string {
    return id.slice(-4);
  }

  static async getOrder(orderId: string): Promise<OrderResponse> {
    if (!Order.client) Order.initClient();

    const { data } = await Order.client.get<OrderResponse>(
      `/orders/v2/${orderId}`,
    );

    return data;
  }

  static formatDateToString(date: Date): string {
    const dateHour = date.getHours().toString().padStart(2, "0");
    const dateMinutes = date.getMinutes().toString().padStart(2, "0");

    return `${dateHour}:${dateMinutes}`;
  }

  constructor(
    public id: string,
    public shortId: string,
    public orderNumber: string,
    public statusesInfo: OrderStatuses,
    public lastStatus: OrderStatus,
    public createdAt: Date,
    public updatedAt: Date,
    public merchantId: string,
    public bag: Bag,
    public paymentMethod: PaymentMethod,
    public expectedDelivery: string,
    public extraExpectDelivery: string,
    public expectedDeliveryDay: string,
    public deliveryMethod: DeliveryMethod,
    public deliveryAddress?: DeliveryAddress,
    public isTakeout?: boolean,
  ) {}

  getInProgressOrConcludedStatusesInfo() {
    return Object.values(this.statusesInfo)
      .filter(
        (statusInfo) =>
          statusInfo?.label &&
          statusInfo?.value &&
          STATUS_IN_PROGRESS_OR_CONCLUDED[
            statusInfo.value as OrderStatusInProgressOrConcluded
          ],
      )
      .sort(function descending(a, b) {
        const aOrder =
          orderInProgressSorter[a.value as OrderStatusInProgressOrConcluded];
        const bOrder =
          orderInProgressSorter[b.value as OrderStatusInProgressOrConcluded];
        return bOrder - aOrder;
      });
  }

  isTodayOrder() {
    const createdAtDate = new Date(this.createdAt).setHours(0, 0, 0, 0);
    const today = new Date().setHours(0, 0, 0, 0);

    return createdAtDate === today;
  }

  isPlaced() {
    return (
      this.lastStatus === "PLACED" ||
      (this.lastStatus === "CREATED" && !this.isWaitingPayment())
    );
  }

  isInProgress() {
    return (
      !this.isConcluded() && !this.isCancelled() && !this.isWaitingPayment()
    );
  }

  isConcluded() {
    return this.lastStatus === "CONCLUDED";
  }

  isPaymentRefused() {
    return (
      this.lastStatus === "DECLINED" || this.lastStatus === "PAYMENT_DECLINED"
    );
  }

  isCancelled() {
    return this.lastStatus === "CANCELLED" || this.isPaymentRefused();
  }

  isWaitingPayment() {
    return (
      this.paymentMethod.isPix() &&
      this.lastStatus === "CREATED" &&
      !this.paymentMethod.hasTTLExpired()
    );
  }

  isDispatched() {
    return this.lastStatus === "DISPATCHED";
  }

  isDeliveryOrder() {
    return this.deliveryMethod === "DELIVERY";
  }

  getNeighborhoodAddress() {
    const { deliveryAddress } = this;
    return `${deliveryAddress?.district}, ${deliveryAddress?.city} - ${deliveryAddress?.state}`;
  }

  getStreetAddress() {
    const { deliveryAddress } = this;
    if (!deliveryAddress?.address || !deliveryAddress?.streetNumber) return "";
    return `${deliveryAddress?.address}, ${deliveryAddress?.streetNumber}`;
  }

  getHandshakeMetadata() {
    return Object.values(this.statusesInfo).find(
      (statusInfo) =>
        statusInfo.value === "HANDSHAKE_CODE_VALUE" && !!statusInfo.metadata,
    )?.metadata as HandshakeMetadata | undefined;
  }
}
