import { Flex } from "@app/domains/shared/design-system";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
  flex-direction: column;
  gap: ${({ theme }) => theme.space.larger};
`;

export const Body = styled(Flex)`
  text-align: center;
`;

export const Footer = styled(Flex)`
  flex-direction: column;
  gap: ${({ theme }) => theme.space.smaller};
`;
