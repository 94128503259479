import React, { useEffect, useState } from "react";
import { useCheckout } from "@app/domains/checkout/context";
import { PaymentSelector } from "./PaymentSelector";
import { SelectedPayment } from "./SelectedPayment";
import { Flex } from "@ifood/pomodoro-components";
import { useIsMobile } from "@app/domains/shared/hooks";
import { Drawer } from "@app/domains/shared/design-system";
import { Statuses } from "./PaymentSelector/types";
import { Z_INDEXES } from "@app/domains/shared/constants";
import * as S from "./styles";

const DRAWER_TITLES = {
  SELECT_ONLINE_PAYMENT_CARD: "Meus cartões",
  ADD_ONLINE_PAYMENT_CARD: "Adicionar cartão",
};

export const PaymentSelect = React.forwardRef<HTMLDivElement>((_, ref) => {
  const {
    order: { paymentMethod },
  } = useCheckout();
  const isMobile = useIsMobile();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [status, setStatus] = useState<Statuses>("SELECT_PAYMENT_TYPE");

  useEffect(() => {
    if (!paymentMethod) return;
    setIsDrawerOpen(false);
  }, [paymentMethod]);

  const handleOnSwitch = () => setIsDrawerOpen(true);
  const onStatusChange = (newStatus: Statuses) => setStatus(newStatus);
  const onClose = () => setIsDrawerOpen(false);

  return (
    <Flex gap="16px" flexDirection="column" ref={ref}>
      {isMobile ? (
        <>
          <SelectedPayment onSwitch={handleOnSwitch} />
          <Drawer
            title={DRAWER_TITLES[status] ?? "Pagamentos"}
            zIndex={Z_INDEXES.PAYMENT_DRAWER}
            open={isDrawerOpen}
            onClose={onClose}
            placement="bottom"
          >
            <S.DrawerBody>
              <PaymentSelector
                onClose={onClose}
                onStatusChange={onStatusChange}
              />
            </S.DrawerBody>
          </Drawer>
        </>
      ) : (
        <PaymentSelector />
      )}
    </Flex>
  );
});
