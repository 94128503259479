import NextImage from 'next/image';
import { useOrder } from "@app/domains/order/context";
import { Order } from "@app/domains/order/models";
import { OrderInfo } from '../OrderInfo';
import * as S from './styles';

type WaitingPaymentSummaryProps = {
  order: Order;
};

export const WaitingPaymentSummary: React.VFC<WaitingPaymentSummaryProps> = ({ order }) => {
  const { openPixDrawer, openFAQDrawer } = useOrder();

  return (
    <S.Wrapper>
      <S.Container>
        <S.Header>
          <NextImage height={10} width={10} src="/icons/waiting-payment-status.svg" />
          <S.Title>Pedido aguardando pagamento</S.Title>
        </S.Header>
        <S.Button variant="secondary" onClick={() => openPixDrawer(order)}>
          Fazer pagamento
        </S.Button>
        <S.PixFAQButton onClick={openFAQDrawer} variant="small">
          Preciso de ajuda sobre o Pix
        </S.PixFAQButton>
        <OrderInfo order={order} />
      </S.Container>
    </S.Wrapper>
  );
};
