import { Divider, LinkText } from "@app/domains/shared/design-system";
import { useOrder } from "@app/domains/order/context";

import { Order } from "@app/domains/order/models";
import { PaymentMethod } from "../PaymentMethod";
import * as S from "./styles";

type OrderInfoProps = {
  order: Order;
  isCancelled?: boolean;
};

export const OrderInfo: React.VFC<OrderInfoProps> = ({
  order,
  isCancelled = false,
}) => {
  const { openOrderDetails } = useOrder();

  return (
    <S.OrderInfoContainer>
      <S.Wrapper>
        <Divider mt={16} mb={16} mr={0} ml={0} />
      </S.Wrapper>

      <S.Wrapper>
        <S.OrderItemsContainer>
          <S.OrderTitle isCancelled={isCancelled}>
            Pedido N° {order.orderNumber}
          </S.OrderTitle>

          <LinkText variant="small" onClick={() => openOrderDetails(order)}>
            Ver itens
          </LinkText>
        </S.OrderItemsContainer>

        <Divider mt={16} mb={16} mr={0} ml={0} />

        <S.PaymentContainer>
          <PaymentMethod order={order} />

          <S.PriceContainer>
            <S.TotalPriceText isCancelled={isCancelled}>Total</S.TotalPriceText>
            <S.TotalPriceText isCancelled={isCancelled}>
              {order.bag.total.format()}
            </S.TotalPriceText>
          </S.PriceContainer>
        </S.PaymentContainer>
      </S.Wrapper>
    </S.OrderInfoContainer>
  );
};
