import { useCheckout } from "@app/domains/checkout/context";
import { ItemUnit } from "@app/domains/checkout/models";
import { Item as CatalogItem } from "@app/domains/catalog/models";
import { useEffect, useState } from "react";
import { QuickAdd, Tabs } from "@app/domains/shared/design-system";
import { useRouter } from "next/router";
import { useMerchant } from "@app/domains/merchant/context";
import { Tab } from "@ifood/pomodoro-components/dist/Tabs/types";

type QuickAddItemProps = {
  catalogItem: CatalogItem;
  variant?: "large" | "small";
  observation?: string;
  enableExtraButtons?: boolean;
};

export const QuickAddItem: React.VFC<QuickAddItemProps> = ({
  catalogItem,
  variant,
  observation,
  enableExtraButtons,
}) => {
  const { order, addItem, removeItem, getItemInOrder } = useCheckout();
  const { merchant } = useMerchant();
  const router = useRouter();

  const [item, setItem] = useState(catalogItem);
  const [quantity, setQuantity] = useState<number>();
  const [unit, setUnit] = useState<ItemUnit>();

  useEffect(() => {
    const itemInOrder = getItemInOrder(catalogItem.id);
    if (itemInOrder) {
      setItem(itemInOrder.catalogItem);
      setQuantity(itemInOrder.quantity);
      setUnit(itemInOrder.unit);
    } else {
      setUnit(item.isWeigthVariable() ? "WEIGHT" : "UNIT");
      setItem(catalogItem);
      setQuantity(0);
    }
  }, [order.itemsList, catalogItem]);

  useEffect(() => {
    const itemInOrder = getItemInOrder(item.id);
    if (!itemInOrder || !observation) return;
    const timeout = setTimeout(updateItemInOrder, 800);
    return () => clearTimeout(timeout);
  }, [observation]);

  useEffect(() => {
    const itemInOrder = getItemInOrder(item.id);
    if (itemInOrder && itemInOrder.unit !== unit) updateItemInOrder();
  }, [unit]);

  const handleTabsChange = (index: number, tabs: Tab[]) => {
    setUnit(String(tabs[index].key).includes("unit") ? "UNIT" : "WEIGHT");
  };

  const updateItemInOrder = async (newQuantity: number = quantity) => {
    const itemInOrder = getItemInOrder(item.id);
    const newObservation = observation ?? itemInOrder?.observation;

    if (newQuantity > 0) {
      return await addItem(item, newQuantity, unit, newObservation);
    } else if (quantity > 0) {
      return removeItem(item);
    }
  };

  const sellingOption = unit == "WEIGHT" ? item.sellingOption : undefined;
  if (quantity === undefined) return null;

  return (
    <>
      {enableExtraButtons && item.isWeigthVariable() && (
        <Tabs
          marginBottom="-8px"
          width="fit-content"
          onChange={handleTabsChange}
          activeIndex={unit == "UNIT" ? 0 : 1}
        >
          <Tabs.Pane key="unit" label="Unidade"></Tabs.Pane>
          <Tabs.Pane key="weight" label="Peso"></Tabs.Pane>
        </Tabs>
      )}
      <QuickAdd
        enableExtraButtons={enableExtraButtons}
        description={item.description}
        sellingOption={sellingOption}
        onChange={updateItemInOrder}
        startValue={quantity}
        variant={variant}
      />
    </>
  );
};
