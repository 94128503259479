import { PaymentMethod } from "@app/domains/checkout/models";
import { ButtonClean, Text } from "@app/domains/shared/design-system";
import NextImage from "next/image";
import * as S from "./styles";
import { Flex, Heading } from "@ifood/pomodoro-components";
import { getBrandImageURL } from "@app/domains/shared/image-utils";

type ChooseBrandProps = {
  paymentName: string;
  paymentMethods: PaymentMethod[];
  onBack: () => void;
  onChooseBrand: (paymentMethod: PaymentMethod) => void;
};

export const ChooseBrand: React.VFC<ChooseBrandProps> = ({
  onBack,
  paymentName,
  paymentMethods,
  onChooseBrand,
}) => {
  return (
    <Flex flexDirection="column" height="100%">
      <Heading fontSize="20px" lineHeight="24px" mt="0" mb="small">
        {paymentName}
      </Heading>
      <S.Wrapper>
        <Text>Selecione a bandeira:</Text>
        <S.List>
          {paymentMethods.map((paymentMethod) => {
            const brandImageURL = getBrandImageURL(paymentMethod);
            return (
              brandImageURL && (
                <ButtonClean
                  aria-label={paymentMethod.name.toLocaleLowerCase()}
                  key={paymentMethod.id}
                  onClick={() => onChooseBrand(paymentMethod)}
                >
                  <NextImage
                    height={38}
                    width={46}
                    src={brandImageURL}
                    alt=""
                  />
                </ButtonClean>
              )
            );
          })}
        </S.List>
        <Text variant="regular" color="grayDarker" mb={24}>
          O pagamento será feito na entrega ou retirada do pedido.
        </Text>
      </S.Wrapper>
      <S.BackButton onClick={onBack}>Voltar</S.BackButton>
    </Flex>
  );
};
