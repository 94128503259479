import {
  Dialog,
  DialogProps,
  Heading,
} from "@app/domains/shared/design-system";

import * as S from "./styles";

type DesktopProps = {
  title: React.ReactNode;
};

export const Desktop: React.FC<DesktopProps & DialogProps> = ({
  children,
  title,
  ...dialogProps
}) => {
  return (
    <Dialog {...dialogProps}>
      <S.Header>
        <Heading as="h4" variant="small" fontWeight="bold" m="0">
          Ajuda
        </Heading>
      </S.Header>
      <Dialog.Body>
        {title}
        {children}
      </Dialog.Body>
    </Dialog>
  );
};
